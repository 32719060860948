import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import ReadMoreArea from '@foxeian/react-read-more';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import axios from 'axios';
import Thanks from '../../json/thanks.json';
import {
	Row,
	Col,
	Button,
	Input,
	Space,
	Divider,
	notification,
	Affix,
	Select,
	Tooltip,
	Tag,
	List,
	Checkbox,
	Radio,
	Modal,
	Avatar,
	Form,
	Upload,
	Dropdown,
} from 'antd';
import {
	ArrowLeftOutlined,
	WarningOutlined,
	PlusOutlined,
	EditOutlined,
	FileTextOutlined,
	FileImageOutlined,
	UserOutlined,
	AntDesignOutlined,
	DeleteOutlined,
	CloseOutlined,
	FilePdfOutlined,
	InboxOutlined,
	EyeOutlined,
	CaretRightOutlined,
	DownOutlined,
	EllipsisOutlined,
} from '@ant-design/icons';
import {
	XCardBackBg,
	XButtonBack,
	XEditTitle,
	XRadio,
	XAvatarName,
	MainTitle,
	SubTitle,
	ParagraphPage,
	AddressWrap,
	CaseFillingTitle,
	CaseTimelineTitle,
	CaseUpdateCard,
	XCollapseMain,
	XButtonModal,
	XButtonWrap,
	XICDModal,
	XICDModalFooterBTN,
	XAddCodeSticky,
	XDrawerAddCode,
	XTabIcdCpt,
	XCardSupportingDocument,
} from '../../pages/CaseForm/CaseFormStyle';
import {
	XDivider,
	XPara1,
	XTitleHead,
	XLottieMargin,
	Title,
	XCardsGrdBG,
	XButtonAdd,
	XButtonLike,
	XLottieOverlay,
	XButtonConfirm,
	XNotificationBar,
	XButtonReload,
	XButtonCloseBar,
	XCheckboxGroup,
	XRecommendedCard,
	XInputText,
	XViewMoreBTN,
	XRecommendedMobCard,
	XMobDropdown,
	XMobWrap,
} from '../../pages/AnalyseCoverage/AnalyseCoverageStyle';
import {
	XCardSummaryEdit,
	XCardsCaseBg,
	XForm,
	XModal,
	XRoundButton,
	XList,
	XCircle,
	XLabel,
	XModalTitle,
	XModalSubtitle,
	XContainer,
	XTag,
} from '../../styles/GlobalStyle';
import { XButtonImg } from '../../pages/Manage/ManageStyle';
import {
	checkModulePermissions,
	getUserToken,
	toTitleCase,
} from '../../utils/helper';
import config from '../../config';
import {
	getAnalysisApi,
	getAnalysisReset,
} from '../../actions/getAnalysisAction';
import {
	analyseCoverageApi,
	analyseCoverageReset,
} from '../../actions/analyseCoverageAction';
import AnalysisLoader from '../AnalysisLoader/AnalysisLoader';
import { editCaseApi, editCaseReset } from '../../actions/editCaseAction';
import {
	sendFeedbackApi,
	sendFeedbackReset,
} from '../../actions/sendFeedbackAction';
import { XLottie } from '../../pages/Login/LoginStyle';
import {
	reviseCoverageApi,
	reviseCoverageReset,
} from '../../actions/reviseCoverageAction';
import { handleInputChange } from '../../utils/formFunctions';
import dayjs from 'dayjs';
const { TextArea } = Input;
const { Dragger } = Upload;

const items = [
	{
		label: 'Edit',
		key: '1',
		icon: <EditOutlined />,
	},
	{
		type: 'divider',
	},
	{
		label: 'Delete',
		key: '2',
		icon: <DeleteOutlined />,
	},
];

const CaseOperations = (props) => {
	const {
		patientData,
		mainDataLoading,
		handleClinicalDocsChange = () => {},
		clinicalDocumentFileList,
		clinicalDocsForm,
		clinicalDocsLoading,
		handleUploadCancel = () => {},
		setIsClinicalUploadModalOpen,
		isClinicalUploadModalOpen,
		setPreviewImage,
		previewImage,
		setPreviewTitle,
		previewTitle,
		setPdfPreviewOpen,
		pdfPreviewOpen,
		documentsToDisplay,
		clinicalSummaryTextLoading,
		setClinicalSummaryTextLoading,
		showClinicalTextArea,
		setShowClinicalTextArea,
		// setFormData,
		// formData,
		id,
		editCase = () => {},
		handleRemoveClinicalDocuments = () => {},
		setPageContent,
		pageContent,
		clinicalDocumentsToDisplay,
		feedbackId,
		reviseCoverage,
		lastAnalysisData,
		// closeIcdCptDrawer2,
		// closeIcdCptDrawer,
		setLoading,
		loading,
		getBase64 = () => {},
		setClinicalDocsLoading,
		setSubmitClinicalDocs,
		setUpdatedClinicalDocumentFileList,
		form,
		setModalTextArea,
		setModalOpen,
		// handleCodeClick,
		handleCodeClick = () => {},
		handleClinicalSummaryDoc = () => {},
		modalOpen,
		sendFeedback,
		sendFeedbackState,
		modalTextArea,
		updatePageContent,
		missingFieldsForm,
		missingFieldsCardRef,
		handleAddOption,
		missingFields,
		missingFieldInstructions,
		setMissingFieldsModal,
		setMissingFieldInstructions,
		setMissingFields,
		missingFieldTitle,
		missingFieldsModal,
		// LooksGoodLoading,
		// coverageloading,
		// setCoverageLoading,
		// setLooksGoodLoading,
		isOpenAnalysis,
		supportingDocsSummary,
		summary,
		setSummary,
		provider,
		state,
		dateOfBirth = () => {},
		matchGender,
		previewMode,
		aipa,
		currentRevision,
		checkCoverage,
		policyType,
	} = props;
	const [clinicalForm] = XForm.useForm();
	const [previewOpen, setPreviewOpen] = useState(false);
	const [pdfWidth, setPdfWidth] = useState(700);
	const [coverage, setCoverage] = useState('Yes');
	const [formData, setFormData] = useState();

	const handleClinicalDocsUpload = async () => {
		setClinicalDocsLoading(true);

		try {
			if (isOpenAnalysis) {
				console.log(
					'clinicalDocumentFileList-----------------',
					clinicalDocumentFileList,
				);
				handleClinicalSummaryDoc();
			} else {
				clinicalDocsForm
					.validateFields()
					.then(async () => {
						if (clinicalDocumentFileList.length > 0) {
							await Promise.all(
								clinicalDocumentFileList.map(
									async (fileData) => {
										let get_ext =
											fileData.originFileObj.name.split(
												'.',
											);
										let ext = get_ext[get_ext.length - 1];
										const res = await axios.get(
											// `${config.api.base_url}/settings/get-signed-url?action=putObject&folder_name=original_documents&file_name=denial_letter&ext=pdf&id=denial_letter`,
											`${config.api.base_url}/settings/get-signed-url?action=putObject&folder_name=clinical_documents&file_name=${patientData.patient_last_name}${fileData.originFileObj.uid}&ext=${ext}&id=${patientData.case_id}`,
											{
												headers: {
													'Content-type':
														'application/json; charset=UTF-8',
													Authorization:
														'Bearer ' +
														getUserToken(),
												},
											},
										);

										const result = await axios.put(
											res.data.data,
											fileData.originFileObj,
										);

										if (result.status === 200) {
											// const documentPath = `/original_documents/denial_letter/denial_letter.pdf`;
											const documentPath = `/clinical_documents/${patientData.case_id}/${patientData.patient_last_name}${fileData.originFileObj.uid}.${ext}`;
											const newFile = {
												uid: fileData.originFileObj.uid,
												name: fileData.originFileObj
													.name,
												status: 'done',
												url: documentPath,
											};
											setUpdatedClinicalDocumentFileList(
												(prevFileList) => [
													{ url: newFile },
												],
											);
										}
									},
								),
							).then((res) => {
								setSubmitClinicalDocs(true);
							});
						}
						setSubmitClinicalDocs(true);
					})
					.catch((err) => {
						setClinicalDocsLoading(false);
					});
			}
		} catch (error) {
			setSubmitClinicalDocs(false);
			// Handle error if any
			notification.success({
				message: 'Could not upload',
			});
			setClinicalDocsLoading(false);
			console.error('Error occurred:', error);
		}
	};

	//Clinical Docs Upload
	const showClinicalModal = () => {
		setIsClinicalUploadModalOpen(true);
	};

	const handlePreview = async (file) => {
		if (
			file &&
			file.name &&
			file.url &&
			file.name.split('.').includes('pdf')
		) {
			setPreviewImage(`${config.s3_url}${file.url}` || file.preview);
			setPdfPreviewOpen(true);
		} else {
			if (
				!file.url &&
				!file.preview &&
				file.name.split('.').includes('pdf')
			) {
				file.preview = await getBase64(file.originFileObj);
			}
			console.log(
				'file.preview-----------------------------------',
				file.preview,
			);
			setPreviewImage(file.preview);
			setPdfPreviewOpen(true);
			setPreviewTitle(
				file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
			);
		}
	};

	const showClinicalDelete = (title, file) => {
		XModal.confirm({
			title: `Delete ${title}?`,
			content: (
				<p className="modalPara1">
					Are you sure you want to delete - {title}?
				</p>
			),
			icon: <img className="me-2" src="/images/delete-icon.svg" alt="" />,
			okText: 'Yes, Confirm',
			okType: 'danger',
			okButtonProps: {
				style: {
					backgroundColor: isReclaimPage ? '#0E4C88' : '#007299',
					borderColor: isReclaimPage ? '#0E4C88' : '#007299',
					color: '#fff',
					borderRadius: '20px',
				},
			},
			cancelButtonProps: {
				style: {
					backgroundColor: '#fff',
					color: isReclaimPage ? '#0E4C88' : '#007299',
					borderRadius: '20px',
				},
			},
			cancelText: 'No, Cancel',
			className: 'confirmModal',
			onOk: async () => {
				// Make an API call to delete the file from S3 and your collection
				try {
					// Extract file name and extension from the file URL
					const urlParts = file.url.split('/');
					const fileName = urlParts[urlParts.length - 1];
					const case_id = urlParts[urlParts.length - 2];
					const [patientName, ext] = fileName.split('.');

					// Make API calls to delete the file from S3 and collection
					await axios.delete(
						`${config.api.base_url}/settings/delete-signed-url?folder_name=clinical_documents&file_name=${patientName}&ext=${ext}&id=${case_id}`,
						{
							headers: {
								Authorization: 'Bearer ' + getUserToken(),
							},
						},
					);

					// Remove the file from supporting_docs array
					const updatedClinicalDocs =
						clinicalDocumentsToDisplay.filter(
							(item) => item.url.uid === file.uid,
						);

					// Make API call to update the supporting_docs field in your case
					await editCase({
						id: id,
						deleted_clinical_docs: updatedClinicalDocs,
						remove: true,
						singleDelete: true,
					});

					console.log(
						'File deleted successfully from S3 and collection',
					);
				} catch (error) {
					console.error('Error deleting file:', error);
					// Handle error
				}
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	const deletePageContent = (item, id) => {
		const updatedPageContent = { ...pageContent };
		updatedPageContent[[item]].splice(id, 1);
		setPageContent(updatedPageContent);
		setModalOpen([false, '', ' ']);
		setModalTextArea('');
		form.resetFields();
		document.getElementById('notification')?.classList.remove('d-none');
	};

	const showDeleteConfirm = (title, id, content, item) => {
		XModal.confirm({
			title: `Delete ${title}?`,
			content: (
				<p className="modalPara1">
					Are you sure you want to delete - {content}?
				</p>
			),
			icon: <img className="me-2" src="/images/delete-icon.svg" alt="" />,
			okText: 'Yes, Confirm',
			okButtonProps: {
				style: {
					backgroundColor: isReclaimPage ? '#0E4C88' : '#007299',
					borderColor: isReclaimPage ? '#0E4C88' : '#007299',
					color: '#fff',
					borderRadius: '20px',
				},
			},
			cancelButtonProps: {
				style: {
					backgroundColor: '#fff',
					color: isReclaimPage ? '#0E4C88' : '#007299',
					borderRadius: '20px',
				},
			},

			okType: 'danger',
			cancelText: 'No, Cancel',
			className: 'confirmModal',
			onOk() {
				deletePageContent(item, id);
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	};

	// old data start
	// const [form] = XForm.useForm();
	// const [missingFieldsForm] = XForm.useForm();
	// const [clinicalDocsForm] = XForm.useForm();

	// const [updateForm] = XForm.useForm();
	// const [newOptionForm] = XForm.useForm();

	// const colors = ['#0C79FE', '#5DC466', '#FFA033', '#B67AD5'];
	// const {
	//     id,
	//     summary,
	//     state,
	//     provider,
	//     analyseCoverage,
	//     analyseCoverageReset,
	//     analyseCoverageState,
	//     reviseCoverage,
	//     reviseCoverageReset,
	//     reviseCoverageState,
	//     editCaseReset,
	//     editCaseState,
	//     getAnalysisState,
	//     getAnalysis,
	//     getAnalysisReset,
	//     sendFeedback,
	//     sendFeedbackReset,
	//     sendFeedbackState,
	//     editCase,
	//     getAppealLetter,
	//     getAppealLetterState,
	//     saveAppealLetter,
	//     saveAppealLetterState,
	//     saveAppealLetterReset,
	//     generateAppealLetterState,
	//     generateAppealLetter,
	//     generateAppealLetterReset,
	//     getConfigurationsState,
	//     getConfigurations,
	//     mainDataLoading,
	//     setMainDataLoading,
	//     pathnameIncludesCaseMA,
	//     isShazaam,
	//     supportingFiles,
	//     clinicalDocs,
	//     setShowAfterShazaam,
	//     setOriginalQuery,
	// } = props;

	// const [showClinicalTextArea, setShowClinicalTextArea] = useState(false);
	// const [pageContent, setPageContent] = useState({
	//     pastHistory: [],
	//     presentComplaint: [],
	//     recommendedProcedure: [],
	//     medicalCoverage: [],
	// });
	// const [feedbackId, setFeedbackId] = useState(null);
	// const [coverageloading, setCoverageLoading] = useState(false);
	// const [LooksGoodLoading, setLooksGoodLoading] = useState(false);
	// const [modalOpen, setModalOpen] = useState([false, '', '', '', '']);
	// const [modalTextArea, setModalTextArea] = useState('');
	// const [missingFieldsModal, setMissingFieldsModal] = useState(false);
	// const [missingFields, setMissingFields] = useState([]);
	// const [missingFieldInstructions, setMissingFieldInstructions] = useState(
	//     [],
	// );
	// const [patientData, setPatientData] = useState({});
	// const [lastAnalysisData, setLastAnalysisData] = useState({});
	// const [missingFieldTitle, setMissingFieldTitle] = useState('');
	// const [clinicalDocumentFileList, setClinicalDocumentFileList] = useState(
	//     [],
	// );
	// const [clinicalDocsLoading, setClinicalDocsLoading] = useState(false);

	// const [documentsToDisplay, setDocumentsToDisplay] = useState([]);
	// const [clinicalSummaryTextLoading, setClinicalSummaryTextLoading] =
	//     useState(false);
	// const [clinicalDocumentsToDisplay, setClinicalDocumentsToDisplay] =
	//     useState([]);

	// const [formData, setFormData] = useState({
	//     status: 'success',
	//     successful_claim_commission_criteria: 'percentage',
	//     successful_claim_commission_fixed_value: null,
	//     successful_claim_commission_percentage_value: null,
	//     rejected_claim_commission_criteria: 'percentage',
	//     rejected_claim_commission_fixed_value: null,
	//     rejected_claim_commission_percentage_value: null,
	//     case_amount: null,
	//     claim_amount: null,
	//     status_logs: [],
	// });

	// const [fileList, setFileList] = useState([]);

	// const [previewTitle, setPreviewTitle] = useState('Upload Acknowledgement');
	// const [selectedOption, setSelectedOption] = useState('textarea'); // Initial value can be 'textarea' or 'upload'
	// const [previewOpen, setPreviewOpen] = useState(false);
	// const [pdfPreviewOpen, setPdfPreviewOpen] = useState(false);

	// const [
	//     updatedClinicalDocumentFileList,
	//     setUpdatedClinicalDocumentFileList,
	// ] = useState([]);
	// const [newCheckBoxModal, setNewCheckBoxModal] = useState([false, null]);
	// const [newOptionText, setNewOptionText] = useState('');
	// const [analysisCompleted, setAnalysisCompleted] = useState('');
	// const [isMissingFieldsThere, setIsMissingFieldsThere] = useState(false);
	// const [showAnalysisData, setShowAnalysisData] = useState(false);
	// const [flag, setFlag] = useState(true);
	// const [pdfWidth, setPdfWidth] = useState(700); // Initial width of the modal
	// const [coverage, setCoverage] = useState('Yes');
	// const [thanksModal, setThanksModal] = useState([false, '']);

	// const showDeleteConfirm = (title, id, content, item) => {
	//     XModal.confirm({
	//         title: `Delete ${title}?`,
	//         content: (
	//             <p className="modalPara1">
	//                 Are you sure you want to delete - {content}?
	//             </p>
	//         ),
	//         icon: <img className="me-2" src="/images/delete-icon.svg" alt="" />,
	//         okText: 'Yes, Confirm',
	//         okType: 'danger',
	//         cancelText: 'No, Cancel',
	//         className: 'confirmModal',
	//         onOk() {
	//             deletePageContent(item, id);
	//         },
	//         onCancel() {
	//             console.log('Cancel');
	//         },
	//     });
	// };

	// const handleAddOption = (index) => {
	//     setNewCheckBoxModal([true, index]);
	//     setNewOptionText('');
	//     newOptionForm.setFieldsValue({});
	//     newOptionForm.resetFields();
	// };

	// const handleClinicalDocsChange = ({ fileList: newFileList }) => {
	//     const updatedFileList = newFileList.map((file) => {
	//         if (file.status === 'error') {
	//             return { ...file, status: 'done' };
	//         }
	//         return file;
	//     });
	//     setClinicalDocumentFileList(updatedFileList);
	//     clinicalDocsForm.validateFields(['clinical_docs']);
	// };

	// const deletePageContent = (item, id) => {
	//     const updatedPageContent = { ...pageContent };
	//     updatedPageContent[[item]].splice(id, 1);
	//     setPageContent(updatedPageContent);
	//     setModalOpen([false, '', ' ']);
	//     setModalTextArea('');
	//     form.resetFields();
	//     document.getElementById('notification')?.classList.remove('d-none');
	// };

	// const updatePageContent = (title, id, content) => {
	//     if (id === null) {
	//         setPageContent({
	//             ...pageContent,
	//             [title]: [...pageContent[[title]], content],
	//         });
	//     } else {
	//         const updatedPageContent = { ...pageContent };
	//         updatedPageContent[[title]][id] = content;
	//         setPageContent(updatedPageContent);
	//     }
	//     setModalOpen([false, '', ' ']);
	//     setModalTextArea('');
	//     form.resetFields();
	//     document.getElementById('notification')?.classList.remove('d-none');
	// };

	// useEffect(() => {
	//     if (getAnalysisState.apiState === 'error') {
	//         setMainDataLoading(false);
	//     }
	//     if (getAnalysisState.apiState === 'success') {
	//         setMainDataLoading(false);
	//         setShowClinicalTextArea(false);
	//         if (
	//             getAnalysisState.data.case_details &&
	//             getAnalysisState.data.case_details.allocated_to === null
	//         ) {
	//             notification.error({
	//                 message: 'Case Not Allocated',
	//                 description:
	//                     "Oops! It seems like this case hasn't been allocated to anyone yet. Please allocate it first before proceeding with the analysis.",
	//             });
	//         }

	//         //console.log(getAnalysisState.data.case_details);
	//         if (isShazaam) {
	//             setOriginalQuery(
	//                 getAnalysisState.data.coverage_feedback_response[
	//                 'original_query'
	//                 ] ?? '-',
	//             );
	//         }

	//         setPageContent({
	//             ...pageContent,
	//             originalQuery:
	//                 getAnalysisState.data.coverage_feedback_response[
	//                 'original_query'
	//                 ] ?? '-',
	//             pastHistory:
	//                 getAnalysisState.data.coverage_feedback_response[
	//                 'Past History'
	//                 ] ?? [],
	//             presentComplaint:
	//                 getAnalysisState.data.coverage_feedback_response[
	//                 'Present Complaint'
	//                 ] ?? [],
	//             recommendedProcedure:
	//                 getAnalysisState.data.coverage_feedback_response[
	//                 'Suggested Treatment'
	//                 ] ?? [],
	//             medicalCoverage: [
	//                 getAnalysisState.data.coverage_feedback_response[
	//                     'Medicare Evaluation'
	//                 ]
	//                     ? getAnalysisState.data.coverage_feedback_response[
	//                     'Medicare Evaluation'
	//                     ]['Coverage']
	//                     : 'No',
	//                 getAnalysisState.data.coverage_feedback_response[
	//                     'Medicare Evaluation'
	//                 ]
	//                     ? getAnalysisState.data.coverage_feedback_response[
	//                     'Medicare Evaluation'
	//                     ]['Rationale']
	//                     : '-',
	//                 getAnalysisState.data.coverage_feedback_response[
	//                     'Medicare Evaluation'
	//                 ]
	//                     ? getAnalysisState.data.coverage_feedback_response[
	//                     'Medicare Evaluation'
	//                     ]['reference']
	//                     : null,
	//             ],
	//             missing_points_for_coverage:
	//                 getAnalysisState.data.coverage_feedback_response
	//                     .missing_points_for_coverage ?? [],
	//             status: getAnalysisState.data.coverage_feedback_response.status,
	//         });
	//         // commented
	//         // if (
	//         // 	getAnalysisState.data.coverage_feedback_response &&
	//         // 	getAnalysisState.data.coverage_feedback_response.age
	//         // )
	//         // 	setMatchDateOfbirth(
	//         // 		compareDates(
	//         // 			getAnalysisState.data.case_details.date_of_birth,
	//         // 			getAnalysisState.data.coverage_feedback_response.age,
	//         // 		),
	//         // 	);
	//         // if (
	//         // 	getAnalysisState.data.coverage_feedback_response &&
	//         // 	getAnalysisState.data.coverage_feedback_response.gender
	//         // )
	//         // 	setMatchGender(
	//         // 		compareGender(
	//         // 			getAnalysisState.data.case_details.gender,
	//         // 			getAnalysisState.data.coverage_feedback_response.gender,
	//         // 		),
	//         // 	);
	//         setLastAnalysisData(
	//             getAnalysisState.data?.coverage_feedback_response,
	//         );

	//         setFeedbackId(getAnalysisState.data.coverage_feedback_response._id);
	//         document.getElementById('getAns')?.classList.add('top-0');
	//         document.getElementById('getAns')?.classList.remove('top-100');
	//         // setTimelineData(getAnalysisState.data?.timelineData);

	//         if (getAnalysisState.data.case_details) {
	//             setPatientData(getAnalysisState.data.case_details);

	//             const revenueCycleDocs =
	//                 getAnalysisState.data.case_details
	//                     .revenue_cycle_supporting_documents;

	//             if (revenueCycleDocs && revenueCycleDocs.length > 0) {
	//                 // Modify fileList to add the prefix to URLs
	//                 const modifiedFileList = revenueCycleDocs.map((file) => ({
	//                     ...file,
	//                     url: `${config.s3_url}${file.url}`,
	//                 }));
	//                 setFileList([...modifiedFileList]);
	//                 setSelectedOption('upload');
	//                 setSelectedOption('upload');
	//             }

	//             const supportingDocs =
	//                 getAnalysisState.data.case_details.supporting_docs.length >
	//                 0 &&
	//                 getAnalysisState.data.case_details.supporting_docs.map(
	//                     (doc) => {
	//                         return doc;
	//                     },
	//                 );
	//             setDocumentsToDisplay(supportingDocs);

	//             const clinicalDocs =
	//                 getAnalysisState.data.case_details.clinical_docs.map(
	//                     (doc) => {
	//                         return doc;
	//                     },
	//                 );
	//             setClinicalDocumentsToDisplay(clinicalDocs);
	//         }

	//         const isAnyArrayNotEmpty = Object.keys(pageContent)
	//             .filter((key) => key !== 'medicalCoverage')
	//             .some((key) => pageContent[key].length > 0);

	//         // setShouldDisplayCards(isAnyArrayNotEmpty);
	//     }
	// }, [getAnalysisState.apiState]);

	// useEffect(() => {
	//     if (getAnalysisState.apiState === 'success') {
	//         setShowClinicalTextArea(false);
	//         const { coverage_feedback_response } = getAnalysisState.data;

	//         // Check if coverage_feedback_response is empty or if "Medicare Evaluation" is missing or empty
	//         const isMedicareEvaluationEmpty =
	//             !coverage_feedback_response?.['Medicare Evaluation'] ||
	//             Object.keys(coverage_feedback_response['Medicare Evaluation'])
	//                 .length === 0;

	//         if (
	//             patientData.clinical_summary &&
	//             (!coverage_feedback_response ||
	//                 Object.keys(coverage_feedback_response).length === 0 ||
	//                 isMedicareEvaluationEmpty)
	//         ) {
	//             if (patientData.allocated_to) {
	//                 setLoading(true);
	//                 document
	//                     .getElementById('notification')
	//                     ?.classList.add('d-none');
	//                 analyseCoverage({
	//                     clinicalSummary: patientData.clinical_summary,
	//                     // case_id: patientData._id,
	//                     state: patientData.state,
	//                     provider: patientData.insurance_company?.name,
	//                     supportingDocsSummary: patientData.summary_docs,
	//                     ...(isShazaam
	//                         ? { analysis_id: id }
	//                         : { case_id: patientData._id }),
	//                 });
	//             }
	//         }
	//     }
	//     getAnalysisReset();
	// }, [patientData]);

	// useEffect(() => {
	//     // console.log('visShazaam', isShazaam);
	//     if (id && !isShazaam) {
	//         getAnalysis({
	//             id: id,
	//             process_type: pathnameIncludesCaseMA ? null : 'rc',
	//         });
	//     }
	//     if (isShazaam) {
	//         const formData = new FormData();
	//         // Append supportingDocs files
	//         supportingFiles.forEach((file) => {
	//             formData.append('supporting_docs', file.url?.originFileObj); // 'supportingDocs' will be the backend key
	//         });

	//         // Append clinicalDocs files
	//         clinicalDocs.forEach((file) => {
	//             formData.append('clinical_docs', file.originFileObj); // 'clinicalDocs' will be the backend key
	//         });
	//         formData.append('clinicalSummary', summary);
	//         formData.append('state', state);
	//         formData.append('provider', provider);
	//         formData.append('analysis_id', id);
	//         // Log to check FormData contents
	//         setLoading(true);
	//         analyseCoverage(formData);
	//         // analyseCoverage({
	//         // 	clinicalSummary: summary,
	//         // 	state: state,
	//         // 	provider: provider,
	//         // 	analysis_id: id, // Use the newly generated ID here
	//         // });
	//     }
	//     // getConfigurations({});
	// }, [id]);
	// console.log('case analysisi input called');
	// console.log('pageContent===============================', pageContent);

	// useEffect(() => {
	//     console.log('analyseCoverageState called');
	//     if (
	//         analyseCoverageState.apiState == 'success'
	//         // id === analyseCoverageState.data?.case_id
	//     ) {
	//         setFeedbackId(analyseCoverageState.data.feedback_id);
	//         setLastAnalysisData(analyseCoverageState.data);
	//         console.log(
	//             'analyseCoverageState.data',
	//             analyseCoverageState.data['original_query'],
	//         );
	//         if (isShazaam) {
	//             setOriginalQuery(
	//                 analyseCoverageState.data['original_query'] ?? '-',
	//             );
	//         }

	//         setPageContent({
	//             ...pageContent,
	//             originalQuery:
	//                 analyseCoverageState.data['original_query'] ?? '-',
	//             pastHistory: analyseCoverageState.data['Past History'] ?? [],
	//             presentComplaint:
	//                 analyseCoverageState.data['Present Complaint'] ?? [],
	//             recommendedProcedure:
	//                 analyseCoverageState.data['Suggested Treatment'] ?? [],
	//             medicalCoverage: [
	//                 analyseCoverageState.data['Medicare Evaluation']
	//                     ? analyseCoverageState.data['Medicare Evaluation'][
	//                     'Coverage'
	//                     ]
	//                     : 'No',
	//                 analyseCoverageState.data['Medicare Evaluation']
	//                     ? analyseCoverageState.data['Medicare Evaluation'][
	//                     'Rationale'
	//                     ]
	//                     : '',
	//                 analyseCoverageState.data['Medicare Evaluation']
	//                     ? analyseCoverageState.data['Medicare Evaluation'][
	//                     'reference'
	//                     ]
	//                     : null,
	//             ],
	//             missing_points_for_coverage:
	//                 analyseCoverageState.data.missing_points_for_coverage ?? [],
	//         });

	//         // setAnalysisCompleted('success');
	//         analyseCoverageReset();
	//         // if (
	//         // 	analyseCoverageState.data &&
	//         // 	analyseCoverageState.data.date_of_birth
	//         // )
	//         // 	setMatchDateOfbirth(
	//         // 		compareDates(
	//         // 			patientData.date_of_birth,
	//         // 			analyseCoverageState.data.age,
	//         // 		),
	//         // 	);
	//         // if (analyseCoverageState.data && analyseCoverageState.data.gender)
	//         // 	setMatchGender(
	//         // 		compareGender(
	//         // 			patientData.gender,
	//         // 			analyseCoverageState.data.gender,
	//         // 		),
	//         // 	);
	//         if (
	//             analyseCoverageState.data.hasOwnProperty('is_fields_missing') &&
	//             analyseCoverageState.data.is_fields_missing &&
	//             analyseCoverageState.data.hasOwnProperty('missing_fields')
	//         ) {
	//             if (analyseCoverageState.data.missing_fields.length > 0) {
	//                 setMissingFields(
	//                     analyseCoverageState.data.missing_fields ?? [],
	//                 );
	//                 setMissingFieldInstructions(
	//                     analyseCoverageState.data.missing_field_instructions ??
	//                     [],
	//                 );
	//                 setMissingFieldTitle(
	//                     analyseCoverageState.data.missing_field_title ?? '',
	//                 );
	//                 setMissingFieldsModal(true);
	//                 setIsMissingFieldsThere(true);
	//             }
	//         } else {
	//             setShowAnalysisData(true);
	//             setIsMissingFieldsThere(false);
	//             // if (isShazaam) {
	//             // 	setShowAfterShazaam(true);
	//             // }
	//         }
	//         // if (isShazaam)
	//         // 	getAnalysis({
	//         // 		feedback_id: analyseCoverageState.data.feedback_id,
	//         // 	});
	//         // document.getElementById('getAns')?.classList.add('top-0');
	//         // document.getElementById('getAns')?.classList.remove('top-100');
	//         setLoading(false);
	//         if (isShazaam) {
	//             document.getElementById('getQust').classList.add('bottom-100');
	//             document.getElementById('getAns').classList.add('top-0');
	//             document.getElementById('getAns').classList.remove('top-100');
	//             document.getElementById('getAns')?.classList.remove('d-none');
	//         }
	//     } else if (analyseCoverageState.apiState === 'error') {
	//         setShowClinicalTextArea(false);
	//         // setAnalysisCompleted('error');
	//         setIsMissingFieldsThere(false);
	//         setLoading(false);
	//         notification.error({
	//             message:
	//                 'Hey, we are facing high traffic. Please check back in few minutes.',
	//         });
	//         analyseCoverageReset();
	//     }
	// }, [analyseCoverageState]);

	// useEffect(() => {
	//     if (editCaseState.apiState === 'loading') {
	//         // setUpdateCaseLoading(true);
	//     }
	//     if (editCaseState.apiState === 'success') {
	//         // setCurrentActiveCollapse(null);
	//         // setPrevActiveCollapse(null);
	//         setClinicalSummaryTextLoading(false);
	//         handleUploadCancel();
	//         setClinicalDocsLoading(false);
	//         setShowClinicalTextArea(false);
	//         if (isShazaam) {
	//             getAnalysis({ feedback_id: feedbackId });
	//         } else {
	//             getAnalysis({ id: id });
	//         }

	//         // if (flag)
	//         notification.success({
	//             message: editCaseState.message,
	//         });
	//         setModalOpen([false, '']);
	//         editCaseReset();

	//         updateForm.setFieldsValue({});

	//         updateForm.resetFields();
	//         // setSubmitCase(false);
	//         setSelectedOption('');
	//         setFileList([]);
	//         // if (flag)
	//         //  getAnalysis({ id: id });
	//         // navigate(-1);
	//     }
	//     // setFlag(true);
	//     if (editCaseState.apiState === 'error') {
	//         setClinicalSummaryTextLoading(false);
	//         handleUploadCancel();
	//         setClinicalDocsLoading(false);
	//         setShowClinicalTextArea(false);
	//         // setUpdateModalButtonText('Update');
	//         // setUpdateCaseLoading(false);

	//         notification.error({
	//             message: editCaseState.message
	//                 ? editCaseState.message
	//                 : 'Unable to update case status, Please try again',
	//         });
	//     }
	//     editCaseReset();
	// }, [editCaseState]);

	// useEffect(() => {
	//     if (sendFeedbackState.apiState === 'success') {
	//         setCoverageLoading(false);
	//         setLooksGoodLoading(false);
	//         setModalOpen([false, '', '']);
	//         if (sendFeedbackState.data === 'negative')
	//             setThanksModal([true, 'Thanks for making us Better!']);
	//         else
	//             setThanksModal([true, 'Case has been analysed and confirmed!']);
	//         sendFeedbackReset();
	//         if (isShazaam) {
	//             getAnalysis({ feedback_id: feedbackId });
	//         } else {
	//             if (id) getAnalysis({ id: id });
	//         }
	//     }
	//     if (sendFeedbackState.apiState === 'error') {
	//         setCoverageLoading(false);
	//         setLooksGoodLoading(false);
	//     }
	// }, [sendFeedbackState]);

	// useEffect(() => {
	//     console.log('reviseCoverageState called');
	//     if (
	//         reviseCoverageState.apiState == 'success'
	//         // id === reviseCoverageState.data?.case_id
	//     ) {
	//         console.log('reviseCoverageState called inside');
	//         setShowClinicalTextArea(false);
	//         setFeedbackId(reviseCoverageState.data.feedback_id);
	//         setLastAnalysisData(reviseCoverageState.data);
	//         if (isShazaam) {
	//             setOriginalQuery(
	//                 reviseCoverageState.data['original_query'] ?? '',
	//             );
	//         }

	//         setPageContent({
	//             ...pageContent,
	//             originalQuery: reviseCoverageState.data['original_query'] ?? '',
	//             pastHistory: reviseCoverageState.data['Past History'] ?? [],
	//             presentComplaint:
	//                 reviseCoverageState.data['Present Complaint'] ?? [],
	//             recommendedProcedure:
	//                 reviseCoverageState.data['Suggested Treatment'] ?? [],
	//             medicalCoverage: [
	//                 reviseCoverageState.data['Medicare Evaluation']
	//                     ? reviseCoverageState.data['Medicare Evaluation'][
	//                     'Coverage'
	//                     ]
	//                     : 'No',
	//                 reviseCoverageState.data['Medicare Evaluation']
	//                     ? reviseCoverageState.data['Medicare Evaluation'][
	//                     'Rationale'
	//                     ]
	//                     : '',
	//                 reviseCoverageState.data['Medicare Evaluation']
	//                     ? reviseCoverageState.data['Medicare Evaluation'][
	//                     'reference'
	//                     ]
	//                     : null,
	//             ],
	//             missing_points_for_coverage:
	//                 reviseCoverageState.data.missing_points_for_coverage ?? [],
	//             status: reviseCoverageState.data.status,
	//         });
	//         setAnalysisCompleted('success');

	//         // if (reviseCoverageState.data && reviseCoverageState.data.age)
	//         // 	setMatchDateOfbirth(
	//         // 		compareDates(
	//         // 			patientData.date_of_birth,
	//         // 			reviseCoverageState.data.age,
	//         // 		),
	//         // 	);
	//         // if (reviseCoverageState.data && reviseCoverageState.data.gender)
	//         // 	setMatchGender(
	//         // 		compareGender(
	//         // 			patientData.gender,
	//         // 			reviseCoverageState.data.gender,
	//         // 		),
	//         // 	);
	//         if (
	//             reviseCoverageState.data.hasOwnProperty('is_fields_missing') &&
	//             reviseCoverageState.data.is_fields_missing &&
	//             reviseCoverageState.data.hasOwnProperty('missing_fields')
	//         ) {
	//             if (reviseCoverageState.data.missing_fields.length > 0) {
	//                 setMissingFields(
	//                     reviseCoverageState.data.missing_fields ?? [],
	//                 );
	//                 setMissingFieldInstructions(
	//                     reviseCoverageState.data.missing_field_instructions ??
	//                     [],
	//                 );
	//                 setMissingFieldTitle(
	//                     reviseCoverageState.data.missing_field_title ?? '',
	//                 );
	//                 setMissingFieldsModal(true);
	//                 setIsMissingFieldsThere(true);
	//             }
	//         } else {
	//             setShowAnalysisData(true);
	//             setIsMissingFieldsThere(false);
	//             // if (isShazaam) {
	//             // 	setShowAfterShazaam(true);
	//             // }
	//         }

	//         reviseCoverageReset();
	//         if (isShazaam) {
	//             console.log('1');
	//             setLoading(false);
	//             document.getElementById('getQust').classList.add('bottom-100');
	//             document.getElementById('getAns').classList.add('top-0');
	//             document.getElementById('getAns').classList.remove('top-100');
	//             document.getElementById('getAns')?.classList.remove('d-none');
	//         }
	//     } else if (reviseCoverageState.apiState == 'error') {
	//         setShowClinicalTextArea(false);
	//         setLoading(false);
	//         setAnalysisCompleted('error');
	//         notification.error({
	//             message:
	//                 'Hey, we are facing high traffic. Please check back in few minutes.',
	//         });
	//         document.getElementById('notification')?.classList.remove('d-none');
	//     }
	// }, [reviseCoverageState.apiState]);

	// useEffect(() => {
	//     if (submitClinicalDocs) {
	//         editCase({
	//             id: id,
	//             clinical_docs: updatedClinicalDocumentFileList,
	//             upload_documents: true,
	//             remove: true,
	//         });
	//     }
	// }, [submitClinicalDocs]);

	const location = useLocation();
	const isReclaimPage = location.pathname === '/reclaim';

	return (
		<>
			{isOpenAnalysis ? (
				<></>
			) : (
				<XCardBackBg className="mb-3">
					<Row align={'middle'} className="align-items-center">
						<Col xs={24} sm={12} lg={12}>
							<Space>
								<XAvatarName size="large" gap="4">
									{patientData.patient_first_name &&
										patientData?.patient_first_name[0]}
									{patientData.patient_last_name &&
										patientData?.patient_last_name[0]}
									{/* {patientData.patient_first_name[0]}
									{patientData.patient_last_name[0]} */}
								</XAvatarName>
								<MainTitle
									style={{
										color:
											currentRevision?.field ===
												'patient_first_name' ||
											currentRevision?.field ===
												'patient_last_name'
												? '#007299'
												: '',
									}}
								>
									{currentRevision?.field ===
									'patient_first_name'
										? `${currentRevision?.newValue}${' '}${
												patientData.patient_last_name &&
												patientData?.patient_last_name
										  }`
										: currentRevision?.field ===
										  'patient_last_name'
										? `${
												patientData.patient_first_name &&
												patientData?.patient_first_name
										  }${currentRevision?.newValue}`
										: patientData.patient_name}

									<br />
									<span
										style={{
											backgroundColor:
												currentRevision?.field === 'mrn'
													? 'rgba(223,235,240,0.5)'
													: '',
											color:
												currentRevision?.field === 'mrn'
													? '#007299'
													: '',
										}}
									>
										{currentRevision?.field === 'mrn'
											? currentRevision?.newValue
											: patientData.mrn}
									</span>
								</MainTitle>
							</Space>
						</Col>
						{/* //todo age is redundant and commented after amin told */}
						{/* <Col
													xs={24}
													sm={12}
													lg={12}
													className="text-end"
												>
													<ParagraphPage className="mb-0">
														Age:{' '}
														{moment(
															patientData.date_of_birth,
														).fromNow(true)}
														&nbsp;
														{matchDateOfBirth ? (
															<> </>
														) : (
															<Tooltip
																title="The age of the patient does not align with the age provided in the Clinical Summary."
																trigger={
																	'hover'
																}
															>
																<WarningOutlined
																	style={{
																		color: 'red',
																	}}
																/>
															</Tooltip>
														)}
													</XPara1>
												</Col> */}
						<Col xs={24} lg={12} md={12} className="text-end">
							{/* <ParagraphPage className="mb-0">
														Gender:{' '}
														{patientData.gender
															? toTitleCase(
																	patientData.gender,
															  )
															: ''}
														&nbsp;
														{matchGender ? (
															<> </>
														) : (
															<Tooltip
																title="The gender of the patient does not correspond with the gender entered in the Clinical Summary."
																trigger={
																	'hover'
																}
															>
																<WarningOutlined
																	style={{
																		color: 'red',
																	}}
																/>
															</Tooltip>
														)}
													</ParagraphPage> */}
						</Col>
					</Row>
					<AddressWrap className="mt-4">
						<Row gutter={[15, 15]}>
							<Col xs={24} sm={8} lg={8}>
								<div className="heading">
									DOB <br />
									<span
										className={
											currentRevision?.field ===
											'date_of_birth'
												? 'highlight'
												: ''
										}
									>
										{currentRevision?.field ===
										'date_of_birth'
											? dateOfBirth(
													currentRevision?.newValue,
											  )
											: patientData.date_of_birth
											? dateOfBirth(
													patientData.date_of_birth,
											  )
											: ''}
									</span>
								</div>
							</Col>
							<Col xs={24} sm={8} lg={8}>
								<div className="heading">
									Gender <br />
									<span
										className={
											currentRevision?.field === 'gender'
												? 'highlight'
												: ''
										}
									>
										{currentRevision?.field === 'gender'
											? toTitleCase(
													currentRevision?.newValue,
											  )
											: patientData.gender
											? toTitleCase(patientData.gender)
											: ''}
										&nbsp;
										{matchGender ? (
											<> </>
										) : (
											<Tooltip
												title="The gender of the patient does not correspond with the gender entered in the Clinical Summary."
												trigger={'hover'}
											>
												<WarningOutlined
													style={{
														color: 'red',
													}}
												/>
											</Tooltip>
										)}
									</span>
								</div>
							</Col>
							<Col xs={24} sm={8} lg={8}>
								<div className="heading">
									Revenue Cycle
									<br />
									<span
										className={
											currentRevision?.field ===
											'revenue_cycle'
												? 'highlight'
												: ''
										}
									>
										{currentRevision?.field ===
										'revenue_cycle'
											? currentRevision?.newValue ===
													'pre_service' &&
											  'Pre Service'
											: patientData.revenue_cycle ===
													'pre_service' &&
											  'Pre Service'}
										{currentRevision?.field ===
										'revenue_cycle'
											? currentRevision?.newValue ===
													'post_claim' && 'Post Claim'
											: patientData.revenue_cycle ===
													'post_claim' &&
											  'Post Claim'}
										{currentRevision?.field ===
										'revenue_cycle'
											? currentRevision?.newValue ===
													'part_b_drugs' &&
											  'Part B Drugs'
											: patientData.revenue_cycle ===
													'part_b_drugs' &&
											  'Part B Drugs'}
									</span>
								</div>
							</Col>
							<Col xs={24} sm={8} lg={8}>
								<div className="heading">
									Revenue Cycle Stage
									<br />
									<span
										className={
											currentRevision?.field ===
											'revenue_cycle_stage'
												? 'highlight'
												: ''
										}
									>
										{currentRevision?.field ===
										'revenue_cycle_stage'
											? currentRevision?.newValue ===
													'organization_determination' &&
											  'Organization Determination'
											: patientData.revenue_cycle_stage ===
													'organization_determination' &&
											  'Organization Determination'}
										{currentRevision?.field ===
										'revenue_cycle_stage'
											? currentRevision?.newValue ===
													'first_appeal' &&
											  'First Appeal'
											: patientData.revenue_cycle_stage ===
													'first_appeal' &&
											  'First Appeal'}
										{currentRevision?.field ===
										'revenue_cycle_stage'
											? currentRevision?.newValue ===
													'second_appeal' &&
											  'Second Appeal'
											: patientData.revenue_cycle_stage ===
													'second_appeal' &&
											  'Second Appeal'}
										{currentRevision?.field ===
										'revenue_cycle_stage'
											? currentRevision?.newValue ===
													'third_appeal' &&
											  'Third Appeal'
											: patientData.revenue_cycle_stage ===
													'third_appeal' &&
											  'Third Appeal'}
										{currentRevision?.field ===
										'revenue_cycle_stage'
											? currentRevision?.newValue ===
													'fourth_appeal' &&
											  'Fourth Appeal'
											: patientData.revenue_cycle_stage ===
													'fourth_appeal' &&
											  'Fourth Appeal'}
										{currentRevision?.field ===
										'revenue_cycle_stage'
											? currentRevision?.newValue ===
													'judicial_review' &&
											  'Judicial Review'
											: patientData.revenue_cycle_stage ===
													'judicial_review' &&
											  'Judicial Review'}
									</span>
								</div>
							</Col>
							<Col xs={24} sm={8} lg={8}>
								<div className="heading">
									State
									<br />
									<span
										className={
											currentRevision?.field === 'state'
												? 'highlight'
												: ''
										}
									>
										{currentRevision?.field === 'state'
											? currentRevision?.newValue
											: patientData.state}
									</span>
								</div>
							</Col>
							<Col xs={24} md={8} lg={8}>
								<div className="heading">
									Insurance Claim Number <br />
									<span
										className={
											currentRevision?.field ===
											'insurance_claim_number'
												? 'highlight'
												: ''
										}
									>
										{currentRevision?.field ===
										'insurance_claim_number'
											? currentRevision?.newValue
											: patientData?.insurance_claim_number ??
											  '-'}
									</span>
								</div>
							</Col>
							<Col xs={24} lg={8} md={8}>
								<div className="heading">
									Providers Encounter Number <br />
									<span
										className={
											currentRevision?.field ===
											'providers_encounter_number'
												? 'highlight'
												: ''
										}
									>
										{currentRevision?.field ===
										'providers_encounter_number'
											? currentRevision?.newValue
											: patientData?.providers_encounter_number ??
											  '-'}
									</span>
								</div>
							</Col>
							<Col xs={24} sm={8} lg={8}>
								<div className="heading">
									Insurance Company
									<br />
									<span
										className={
											currentRevision?.field ===
											'insurance_company'
												? 'highlight'
												: ''
										}
									>
										{currentRevision?.field ===
										'insurance_company'
											? currentRevision?.newValue?.name
											: patientData.insurance_company
													?.name ?? '-'}
									</span>
								</div>
							</Col>
							<Col xs={24} sm={8} lg={8}>
								<div className="heading">
									Organization
									<br />
									<span
										className={
											currentRevision?.field ===
											'organization'
												? 'highlight'
												: ''
										}
									>
										{currentRevision?.field ===
										'organization'
											? currentRevision?.newValue?.name
											: patientData.organization?.name ??
											  '-'}
									</span>
								</div>
							</Col>
							<Col xs={24} sm={8} lg={8}>
								<div className="heading">
									Hospital
									<br />
									<span
										className={
											currentRevision?.field ===
											'hospital'
												? 'highlight'
												: ''
										}
									>
										{currentRevision?.field === 'hospital'
											? currentRevision?.newValue?.name
											: patientData.hospital?.name ?? '-'}
									</span>
								</div>
							</Col>
							<Col xs={24} sm={8} lg={8}>
								<div className="heading">
									Region
									<br />
									<span
										className={
											currentRevision?.field === 'region'
												? 'highlight'
												: ''
										}
									>
										{currentRevision?.field === 'region'
											? currentRevision?.newValue?.name
											: patientData.region?.name ?? '-'}
									</span>
								</div>
							</Col>

							<Col xs={24} sm={8} lg={8}>
								<div className="heading">
									Allocated to
									<br />
									<span
										className={
											currentRevision?.field ===
											'allocated_to'
												? 'highlight'
												: ''
										}
									>
										{currentRevision?.field ===
										'allocated_to'
											? currentRevision?.newValue?.name
											: patientData.allocated_to?.name ??
											  '-'}
									</span>
								</div>
							</Col>
							<Col xs={24} sm={8} lg={8}>
								<div className="heading">
									Claim Amount
									<br />
									<span
										className={
											currentRevision?.field ===
											'claim_amount'
												? 'highlight'
												: ''
										}
									>
										{currentRevision?.field ===
										'claim_amount'
											? `$${currentRevision?.newValue}`
											: patientData.claim_amount
											? `$${patientData.claim_amount}`
											: '-'}
									</span>
								</div>
							</Col>
							<Col xs={24} sm={8} lg={8}>
								<div className="heading">
									Case Amount
									<br />
									<span
										className={
											currentRevision?.field ===
											'case_amount'
												? 'highlight'
												: ''
										}
									>
										{currentRevision?.field ===
										'case_amount'
											? `$${currentRevision?.newValue}`
											: patientData.case_amount
											? `$${patientData.case_amount}`
											: '-'}
									</span>
								</div>
							</Col>
							<Col xs={24} sm={8} lg={8}>
								<div className="heading">
									Discharge Date
									<br />
									<span
										className={
											currentRevision?.field ===
											'date_of_discharge'
												? 'highlight'
												: ''
										}
									>
										{/* {patientData.date_of_discharge ?? '-'} */}
										{currentRevision?.field ===
										'date_of_discharge'
											? dateOfBirth(
													currentRevision?.newValue,
											  )
											: patientData.date_of_discharge
											? dateOfBirth(
													patientData.date_of_discharge,
											  )
											: ''}
									</span>
								</div>
							</Col>
							<Col xs={24} sm={8} lg={8}>
								<div className="heading">
									Hearing Date
									<br />
									<span
										className={
											currentRevision?.field ===
											'hearing_date'
												? 'highlight'
												: ''
										}
									>
										{/* {patientData.hearing_date ?? '-'} */}
										{currentRevision?.field ===
										'hearing_date'
											? dateOfBirth(
													currentRevision?.newValue,
											  )
											: patientData.hearing_date
											? dateOfBirth(
													patientData.hearing_date,
											  )
											: ''}
									</span>
								</div>
							</Col>
							<Col xs={24} sm={8} lg={8}>
								<div className="heading">
									Admission Date
									<br />
									<span
										className={
											currentRevision?.field ===
											'date_of_admission'
												? 'highlight'
												: ''
										}
									>
										{/* {patientData.date_of_admission ?? '-'} */}
										{currentRevision?.field ===
										'date_of_admission'
											? dateOfBirth(
													currentRevision?.newValue,
											  )
											: patientData.date_of_admission
											? dateOfBirth(
													patientData.date_of_admission,
											  )
											: ''}
									</span>
								</div>
							</Col>
							<Col xs={24} sm={8} lg={8}>
								<div className="heading">
									EOB Date
									<br />
									<span
										className={
											currentRevision?.field ===
											'date_of_eob'
												? 'highlight'
												: ''
										}
									>
										{/* {patientData.date_of_eob ?? '-'} */}
										{currentRevision?.field ===
										'date_of_eob'
											? dateOfBirth(
													currentRevision?.newValue,
											  )
											: patientData.date_of_eob
											? dateOfBirth(
													patientData.date_of_eob,
											  )
											: ''}
									</span>
								</div>
							</Col>

							<Col xs={24} sm={8} lg={8}>
								<div className="heading">
									Process Type
									<br />
									<span
										className={
											currentRevision?.field ===
											'process_type'
												? 'highlight'
												: ''
										}
									>
										{currentRevision?.field ===
										'process_type'
											? currentRevision?.newValue ===
													'ma_standard' &&
											  'MA Standard Appeal Turn-Around-Time (TAT)'
											: patientData.process_type ===
													'ma_standard' &&
											  'MA Standard Appeal Turn-Around-Time (TAT)'}
										{currentRevision?.field ===
										'process_type'
											? currentRevision?.newValue ===
													'ma_expedited' &&
											  'MA Expedited Appeal Turn-Around-Time (TAT)'
											: patientData.process_type ===
													'ma_expedited' &&
											  'MA Expedited Appeal Turn-Around-Time (TAT)'}
										{/* {currentRevision?.field ===
										'process_type'
											? currentRevision?.newValue
											: patientData.process_type ?? '-'} */}
									</span>
								</div>
							</Col>
							<Col xs={24} sm={8} lg={8}>
								<div className="heading">
									Visit Type
									<br />
									<span
										className={
											currentRevision?.field ===
											'visit_type'
												? 'highlight'
												: ''
										}
									>
										{currentRevision?.field === 'visit_type'
											? currentRevision?.newValue
											: patientData.visit_type ?? '-'}
									</span>
								</div>
							</Col>
							{/* status */}
							<Col xs={24} sm={8} lg={8}>
								<div className="heading">
									Status
									<br />
									<span
										className={
											currentRevision?.field === 'status'
												? 'highlight'
												: ''
										}
									>
										{currentRevision?.field === 'status'
											? currentRevision?.newValue
											: patientData.status ?? '-'}
									</span>
								</div>
							</Col>

							{/* New Date of Service Column */}
							<Col xs={24} lg={12} md={12}>
								<div className="heading">
									Date of Service <br />
									{/* Display single dates if available */}
									{currentRevision?.field !==
										'date_of_service' &&
										patientData.date_of_service?.single_date
											?.length > 0 && (
											<>
												{patientData.date_of_service.single_date.map(
													(date, index) => (
														<span key={index}>
															{dayjs(date).format(
																'MM-DD-YYYY',
															)}
															{index <
																patientData
																	.date_of_service
																	.single_date
																	.length -
																	1 && ', '}
														</span>
													),
												)}
											</>
										)}
									{currentRevision?.field ===
										'date_of_service' &&
										currentRevision?.newValue?.single_date
											?.length > 0 && (
											<>
												{currentRevision?.newValue?.single_date.map(
													(date, index) => (
														<span
															className="highlight"
															key={index}
														>
															{dayjs(date).format(
																'MM-DD-YYYY',
															)}
															{index <
																currentRevision
																	.newValue
																	.single_date
																	.length -
																	1 && ', '}
														</span>
													),
												)}
											</>
										)}
									{/* Display a separator if both single dates and date ranges exist */}
									{currentRevision?.field !==
										'date_of_service' &&
										patientData.date_of_service?.single_date
											?.length > 0 &&
										patientData.date_of_service?.date_range
											?.length > 0 &&
										', '}
									{currentRevision?.field ===
										'date_of_service' &&
										currentRevision?.newValue?.single_date
											?.length > 0 &&
										currentRevision?.newValue?.date_range
											?.length > 0 &&
										', '}
									{/* Display date ranges if available */}
									{currentRevision?.field !==
										'date_of_service' &&
										patientData.date_of_service?.date_range
											?.length > 0 && (
											<>
												{patientData.date_of_service.date_range.map(
													(range, index) => (
														<span key={index}>
															{range[0]
																? dayjs(
																		range[0],
																  ).format(
																		'MM-DD-YYYY',
																  )
																: ''}
															{' to '}
															{range[1]
																? dayjs(
																		range[1],
																  ).format(
																		'MM-DD-YYYY',
																  )
																: ''}
															{index <
																patientData
																	.date_of_service
																	.date_range
																	.length -
																	1 && ', '}
														</span>
													),
												)}
											</>
										)}
									{currentRevision?.field ===
										'date_of_service' &&
										currentRevision?.newValue?.date_range
											?.length > 0 && (
											<>
												{currentRevision?.newValue?.date_range.map(
													(range, index) => (
														<span
															className="highlight"
															key={index}
														>
															{range[0]
																? dayjs(
																		range[0],
																  ).format(
																		'MM-DD-YYYY',
																  )
																: ''}
															{' to '}
															{range[1]
																? dayjs(
																		range[1],
																  ).format(
																		'MM-DD-YYYY',
																  )
																: ''}
															{index <
																currentRevision
																	.newValue
																	.date_range
																	.length -
																	1 && ', '}
														</span>
													),
												)}
											</>
										)}
									{/* Placeholder if no dates are selected */}
									{!(
										patientData.date_of_service?.single_date
											?.length > 0 ||
										patientData.date_of_service?.date_range
											?.length > 0
									) && '-'}
								</div>
							</Col>
						</Row>
						{patientData.status === 'letter_sent' ? (
							<Row className="mt-2">
								<Col xl={18} lg={18}>
									<XTag className={patientData.status}>
										{toTitleCase(patientData.status)}
									</XTag>
								</Col>
							</Row>
						) : (
							''
						)}
					</AddressWrap>
				</XCardBackBg>
			)}

			<XCardBackBg className={isReclaimPage ? 'reclaimClass' : ''}>
				<Row gutter={[15, 15]} className="align-items-center">
					<Col xs={12} sm={24} lg={8}>
						<div className="labelHeading">Clinical Summary</div>
					</Col>
					<Col xs={12} sm={24} lg={16} className="text-lg-end">
						<div className="showMobile text-end">
							<Space direction="horizontal">
								<>
									{!showClinicalTextArea && !previewMode && (
										<XButtonConfirm
											className={
												isReclaimPage ? 'btnRefine' : ''
											}
											type="text"
											icon={
												<img
													src={
														isReclaimPage
															? '/images/reclaim-edit-clinical-icon.svg'
															: '/images/edit-clinical-icon.svg'
													}
													style={{
														width: '20px',
													}}
													alt="Edit"
												/>
											}
											onClick={() => {
												setShowClinicalTextArea(true);
												clinicalForm.setFieldsValue({
													clinical_summary:
														isOpenAnalysis
															? summary
															: patientData.clinical_summary,
												});
											}}
											style={{
												background: '#fff',
											}}
										/>
									)}
									{clinicalDocumentsToDisplay &&
									clinicalDocumentsToDisplay.length > 0 ? (
										<>
											{clinicalDocumentsToDisplay &&
												clinicalDocumentsToDisplay.length >
													0 &&
												clinicalDocumentsToDisplay.map(
													(ele) => {
														return (
															<>
																{(isOpenAnalysis
																	? ele?.name
																	: ele?.url
																			.name
																)
																	.split('.')
																	.includes(
																		'pdf',
																	) && (
																	<>
																		<XButtonConfirm
																			type="text"
																			icon={
																				<img
																					src="/images/pdf-new-icon.svg"
																					style={{
																						width: '20px',
																					}}
																					alt="delete"
																				/>
																			}
																			style={{
																				background:
																					'#fff',
																			}}
																			onClick={() =>
																				isOpenAnalysis
																					? handlePreview(
																							ele,
																					  )
																					: handlePreview(
																							ele.url,
																					  )
																			}
																		/>
																		{!previewMode && (
																			<XButtonConfirm
																				type="text"
																				icon={
																					<img
																						src="/images/delete-icon-new.svg"
																						style={{
																							width: '20px',
																						}}
																						alt="delete"
																					/>
																				}
																				style={{
																					background:
																						'#fff',
																				}}
																				onClick={() => {
																					isOpenAnalysis
																						? handleRemoveClinicalDocuments(
																								ele,
																						  )
																						: showClinicalDelete(
																								'Clinical Summary Document',
																								ele.url,
																						  );
																				}}
																			/>
																		)}
																	</>
																)}
															</>
														);
													},
												)}
										</>
									) : (
										<>
											{!previewMode && (
												<XButtonAdd
													onClick={() => {
														showClinicalModal();
													}}
													icon={<PlusOutlined />}
												></XButtonAdd>
											)}
										</>
									)}
								</>
							</Space>
						</div>
						<Space className="d-md-inline-flex showDesktop">
							{!showClinicalTextArea && !previewMode && (
								<XButtonConfirm
									icon={
										<img
											src="/images/edit-clinical-icon.svg"
											style={{
												width: '20px',
											}}
											alt="Edit"
										/>
									}
									className="mb-2 mb-md-0 mb-sm-0"
									type="primary"
									size="large"
									shape="round"
									style={{
										background: '#fff',
									}}
									onClick={() => {
										setShowClinicalTextArea(true);
										clinicalForm.setFieldsValue({
											clinical_summary: isOpenAnalysis
												? summary
												: patientData.clinical_summary,
										});
									}}
								>
									Edit
								</XButtonConfirm>
							)}

							{clinicalDocumentsToDisplay &&
							clinicalDocumentsToDisplay.length > 0 ? (
								<>
									{clinicalDocumentsToDisplay &&
										clinicalDocumentsToDisplay.length > 0 &&
										clinicalDocumentsToDisplay.map(
											(ele) => {
												return (
													<>
														{(isOpenAnalysis
															? ele?.name
															: ele?.url.name
														)
															.split('.')
															.includes(
																'pdf',
															) && (
															<>
																<XButtonConfirm
																	type="primary"
																	size="large"
																	shape="round"
																	icon={
																		<img
																			src="/images/pdf-icon.svg"
																			style={{
																				width: '20px',
																			}}
																			alt="Edit"
																		/>
																	}
																	className="btnActive mb-2 mb-md-0 mb-sm-0"
																	onClick={() =>
																		isOpenAnalysis
																			? handlePreview(
																					ele,
																			  )
																			: handlePreview(
																					ele.url,
																			  )
																	}
																>
																	Clinical
																	Document
																</XButtonConfirm>
																{!previewMode && (
																	<XButtonConfirm
																		icon={
																			<img
																				src="/images/delete-icon-new.svg"
																				alt="Delete"
																			/>
																		}
																		type="primary"
																		size="large"
																		shape="round"
																		style={{
																			background:
																				'#fff',
																		}}
																		onClick={() => {
																			isOpenAnalysis
																				? handleRemoveClinicalDocuments(
																						ele,
																				  )
																				: showClinicalDelete(
																						'Clinical Summary Document',
																						ele.url,
																				  );
																		}}
																	>
																		Delete
																	</XButtonConfirm>
																)}
															</>
														)}
													</>
												);
											},
										)}
								</>
							) : (
								<>
									{!previewMode && (
										<XButtonAdd
											onClick={() => {
												showClinicalModal();
											}}
											icon={<PlusOutlined />}
										></XButtonAdd>
									)}
								</>
							)}
						</Space>
					</Col>
				</Row>
				{((checkCoverage && clinicalDocumentsToDisplay.length === 0) ||
					(checkCoverage && policyType !== 'commercial')) && (
					<>
						<XCardSummaryEdit
							style={{
								...(currentRevision?.field ===
									'clinical_summary' && {
									backgroundColor: 'rgba(223, 235, 240, 0.5)',
									color: '#007299',
								}),
							}}
							className="mt-4 mb-4"
						>
							<Row className="align-items-center">
								{!showClinicalTextArea ? (
									<Col xs={24} lg={24}>
										<XPara1
											style={{
												textAlign: 'justify',
											}}
										>
											<ReadMoreArea
												expandLabel="Read More"
												collapseLabel="Read Less"
												className={
													isReclaimPage
														? 'reclaimReadMore'
														: 'readMore'
												}
												// className={`readMore ${
												// 	currentRevision?.field ===
												// 	'clinical_summary'
												// 		? 'highlight'
												// 		: ''
												// }`}
											>
												{isOpenAnalysis
													? summary
													: // pageContent.originalQuery
													currentRevision?.field ===
													  'clinical_summary'
													? currentRevision?.newValue
													: patientData.clinical_summary}
											</ReadMoreArea>
										</XPara1>
									</Col>
								) : (
									<>
										<Form
											form={clinicalForm}
											onFinish={(values) => {
												setClinicalSummaryTextLoading(
													true,
												);
												// add logic for open analysis
												// Check if open analysis is true
												if (isOpenAnalysis) {
													// Update summary and page content
													// setSummary(
													// 	formData.clinical_summary,
													// );
													updatePageContent(
														'originalQuery',
														null,
														summary,
													);
												} else {
													// Edit case if not open analysis
													editCase({
														id,
														...values,
													});
												}
												// isOpenAnalysis
												// 	? setSummary(formData.clinical_summary)
												// 	 updatePageContent(
												// 			'originalQuery',
												// 			null,
												// 			formData.clinical_summary,
												// 	  )

												// 	: editCase({
												// 			id,
												// 			...values,
												// 	  });
											}}
											className="w-100"
										>
											<Col xs={24} className="text-end">
												<XForm.Item
													name="clinical_summary"
													className="mb-0"
													rules={[
														{
															required: true,
															message:
																'Please enter clinical summary',
														},
													]}
												>
													<TextArea
														name="clinical_summary"
														showCount
														style={{
															marginBottom: 24,
														}}
														className="p-2"
														onChange={(e) => {
															const newSummary =
																e.target.value;

															// Only update summary if isOpenAnalysis is true
															if (
																isOpenAnalysis
															) {
																setSummary(
																	newSummary,
																); // Assuming setSummary updates the summary state
															}

															// Update formData
															handleInputChange(
																e,
																formData,
																setFormData,
															);
														}}
														autoSize={{
															minRows: 5,
														}}
														placeholder="Enter Detailed Clinical Summary"
													/>
												</XForm.Item>
											</Col>
											<Col xs={24} className="text-end">
												<Button
													shape="round"
													style={{
														backgroundColor: '#fff',
														color: isReclaimPage
															? '#0E4C88'
															: '#007299',
														borderRadius: '20px',
														marginRight: '10px',
													}}
													onClick={() => {
														setShowClinicalTextArea(
															false,
														);
													}}
													className="cancel"
												>
													Cancel
												</Button>
												<XButtonConfirm
													loading={
														clinicalSummaryTextLoading
													}
													shape="round"
													type="primary"
													style={{
														backgroundColor:
															isReclaimPage
																? '#0E4C88'
																: '#007299',
														borderColor:
															isReclaimPage
																? '#0E4C88'
																: '#007299',
														color: '#fff',
														borderRadius: '20px',
													}}
													htmlType="submit"
												>
													Submit
												</XButtonConfirm>
											</Col>
										</Form>
									</>
								)}
							</Row>
						</XCardSummaryEdit>
					</>
				)}
				{/* {isOpenAnalysis ? (
					<></>
				) : ( */}
				{(!aipa && documentsToDisplay.length > 0) ||
					(checkCoverage && documentsToDisplay.length > 0 && (
						<XCardSupportingDocument className="mt-3">
							<Row className="mb-4">
								<Col xs={24}>
									<h2>Supporting Document</h2>
								</Col>
							</Row>
							<Row gutter={(15, 15)}>
								{documentsToDisplay.length > 0 &&
									documentsToDisplay.map((doc, index) => {
										const isPDF =
											doc.url.name.split('.').pop() ===
											'pdf';
										return (
											<Col
												xs={24}
												sm={12}
												xl={8}
												key={index}
											>
												<Tooltip
													title={doc.document_name}
													placement="top"
												>
													<Space
														className="uploadDocs"
														onClick={() =>
															handlePreview(
																doc.url,
															)
														}
													>
														<img
															src="/images/pdf.svg"
															alt={
																doc.document_name
															}
														/>
														<div>
															{doc.document_name}
														</div>
													</Space>
												</Tooltip>
											</Col>
										);
									})}
							</Row>
						</XCardSupportingDocument>
					))}

				{/* )} */}
			</XCardBackBg>

			{/* Clinical Summary Upload */}
			<Modal
				title="Upload Clinical Document"
				open={isClinicalUploadModalOpen}
				onOk={handleClinicalDocsUpload}
				okButtonProps={{
					loading: clinicalDocsLoading,
					style: {
						backgroundColor: isReclaimPage ? '#0E4C88' : '#007299',
						borderColor: isReclaimPage ? '#0E4C88' : '#007299',
						color: '#fff',
						borderRadius: '20px',
					},
				}}
				cancelButtonProps={{
					style: {
						backgroundColor: '#fff',
						color: isReclaimPage ? '#0E4C88' : '#007299',
						borderRadius: '20px',
					},
				}}
				onCancel={() => {
					if (!clinicalDocsLoading) {
						handleUploadCancel();
					}
				}}
			>
				<XForm
					form={clinicalDocsForm}
					layout="vertical"
					autoComplete="off"
				>
					<XForm.Item
						name="clinical_docs"
						rules={[
							{
								required: true,
								validator: (_, value) => {
									if (clinicalDocumentFileList.length === 0) {
										return Promise.reject(
											new Error('Please Select file!'),
										);
									}
									return Promise.resolve();
								},
							},
						]}
					>
						<Dragger
							fileList={clinicalDocumentFileList}
							maxCount={1}
							beforeUpload={(file) => {
								const isJPEG = file.type === 'image/jpeg';
								const isJPG = file.type === 'image/jpg';
								const isPNG = file.type === 'image/png';
								const isGIF = file.type === 'image/gif';
								const isVideo = file.type.startsWith('video/');
								const isAudio = file.type.startsWith('audio/');

								const isLt10M = file.size / 1024 / 1024 < 10;

								if (!isLt10M) {
									notification.error({
										message: 'File Size Exceeded',
										description: `The file ${file.name} exceeds the maximum allowed size of 10MB.`,
									});
								}
								if (
									isGIF ||
									isVideo ||
									isAudio ||
									isJPEG ||
									isJPG ||
									isPNG
								) {
									notification.error({
										message: 'File Type Error',
										description: `GIF, video, image and audio files are not allowed.`,
									});
								}

								return (
									isLt10M &&
									!isGIF &&
									!isVideo &&
									!isAudio &&
									!isJPEG &&
									!isJPG &&
									!isPNG
								);
							}}
							// accept=".pdf,.jpeg,.jpg,.png"
							accept=".pdf"
							onChange={handleClinicalDocsChange}
						>
							<p className="ant-upload-drag-icon">
								<InboxOutlined style={{ color: '#007299' }} />
							</p>
							<p className="ant-upload-text">
								Click or drag file to this area to upload
							</p>
						</Dragger>
					</XForm.Item>
				</XForm>
			</Modal>

			{/* Extracted Supporting documents */}
			{!aipa &&
			!mainDataLoading &&
			(patientData?.summary_docs || supportingDocsSummary) ? (
				<XCardBackBg className="mt-3">
					<Row gutter={(15, 15)} className="mb-2 align-items-center">
						<Col xs={24} lg={24}>
							<Space align="center">
								<img
									className="iconResp"
									src="/images/extracted-summary.svg"
									alt=""
								/>
								<XTitleHead>
									<div className="labelHeading">
										Summary From Uploaded Documents
									</div>
								</XTitleHead>
							</Space>
						</Col>
					</Row>
					{!mainDataLoading &&
					(patientData?.summary_docs || supportingDocsSummary) ? (
						<XCardSummaryEdit
							style={{
								textAlign: 'justify',
								...(currentRevision?.field ===
									'summary_docs' && {
									backgroundColor: 'rgba(223, 235, 240, 0.5)',
									color: '#007299',
								}),
							}}
						>
							{isOpenAnalysis
								? supportingDocsSummary
								: currentRevision?.field === 'summary_docs'
								? currentRevision?.newValue
								: patientData.summary_docs}
						</XCardSummaryEdit>
					) : (
						''
					)}
				</XCardBackBg>
			) : (
				''
			)}

			{!aipa && (
				<>
					{/* Past Clicnical History */}
					<XCardBackBg className="mt-3">
						<Row gutter={(15, 15)} className="align-items-center">
							<Col xs={20} lg={20}>
								<Space align="center">
									<img
										className="iconResp"
										src="/images/past-icon.svg"
										alt=""
									/>
									<XTitleHead>
										<span className="color1">
											Past Clinical History
										</span>
									</XTitleHead>
								</Space>
							</Col>
							{!previewMode && (
								<Col xs={4} lg={4} className="text-end">
									<XButtonAdd
										icon={<PlusOutlined />}
										onClick={() => {
											form.setFieldsValue({
												modal_text_area: '',
											});
											setModalTextArea('');
											setModalOpen([
												true,
												'Add Past Clinical History',
												'',
												null,
												'pastHistory',
											]);
										}}
									></XButtonAdd>
								</Col>
							)}
						</Row>
						<XDivider dashed className="mt-3 d-none d-md-block" />
						{currentRevision?.field === 'past_history' &&
						currentRevision?.finalValue &&
						currentRevision?.finalValue.length > 0
							? currentRevision?.finalValue.map((row, index) => {
									const splitPastHistory =
										row.value.split('- ICD-10 Code:');

									return (
										<>
											<XRecommendedMobCard
												bgColor="#ffffff"
												className="mt-2 showMobile"
												style={{
													backgroundColor:
														row.type === 'added'
															? '#eaebfb'
															: row.type ===
															  'removed'
															? 'rgb(255, 236, 236)'
															: '#ffffff',
												}}
											>
												<>
													<Row>
														<Col xs={22}>
															{splitPastHistory.length >
															1 ? (
																<div className="drowdownTitle mb-2">
																	{
																		splitPastHistory[0]
																	}
																</div>
															) : (
																<div className="drowdownTitle mb-2">
																	{row.value}
																</div>
															)}
														</Col>
														{!previewMode && (
															<Col
																xs={2}
																className="text-end"
															>
																<XMobDropdown.Button
																	type="text"
																	disabled
																	menu={{
																		items: [
																			{
																				label: 'Edit',
																				key: '1',
																				icon: (
																					<EditOutlined />
																				),
																				onClick:
																					() => {
																						form.setFieldsValue(
																							{
																								modal_text_area:
																									row,
																							},
																						);
																						setModalTextArea(
																							row,
																						);
																						setModalOpen(
																							[
																								true,
																								`Edit Past Clinical History`,
																								row,
																								index,
																								'pastHistory',
																							],
																						);
																					},
																			},
																			{
																				type: 'divider',
																			},
																			{
																				label: 'Delete',
																				key: '2',
																				icon: (
																					<DeleteOutlined />
																				),
																				onClick:
																					() => {
																						showDeleteConfirm(
																							'Past Clinical History',
																							index,
																							row,
																							'pastHistory',
																						);
																					},
																			},
																		],
																	}}
																	trigger={[
																		'click',
																	]}
																/>
															</Col>
														)}
														{splitPastHistory.length >
															1 && (
															<Col
																xs={24}
																onClick={() => {
																	handleCodeClick(
																		'ICD-10',
																		{
																			code: splitPastHistory[1],
																			description:
																				splitPastHistory[0],
																			codeTypeText:
																				'pastHistory',
																			buttontext:
																				'Add in Past',
																		},
																	);
																}}
																role="button"
															>
																<div className="drowdownSubTitle">
																	ICD-10:
																	{
																		splitPastHistory[1]
																	}
																</div>
															</Col>
														)}
													</Row>
												</>
											</XRecommendedMobCard>
											<XRecommendedCard
												bgColor="#ffffff"
												className="mt- showDesktop"
												style={{
													backgroundColor:
														row.type === 'added'
															? '#eaebfb'
															: row.type ===
															  'removed'
															? 'rgb(255, 236, 236)'
															: '#ffffff',
												}}
											>
												<Row className="align-items-center justify-content-between">
													<Col xs={24} lg={14}>
														{splitPastHistory.length >
														1 ? (
															<XPara1>
																{
																	splitPastHistory[0]
																}
															</XPara1>
														) : (
															<XPara1>
																{row.value}
															</XPara1>
														)}
													</Col>
													{splitPastHistory.length >
														1 && (
														<Col
															xs={24}
															lg={6}
															className="text-center"
															style={{
																borderLeft:
																	'1px solid #57636F26',
																borderRight:
																	'1px solid #57636F26',
															}}
															onClick={() => {
																handleCodeClick(
																	'ICD-10',
																	{
																		code: splitPastHistory[1],
																		description:
																			splitPastHistory[0],
																		codeTypeText:
																			'pastHistory',
																		buttontext:
																			'Add in Past',
																	},
																);
															}}
															role="button"
														>
															<XPara1>
																<span className="hoverGradient">
																	ICD-10:
																	{
																		splitPastHistory[1]
																	}
																</span>
															</XPara1>
														</Col>
													)}
													{!previewMode && (
														<Col
															xs={24}
															lg={4}
															className="text-center"
															style={{
																padding: '15px',
																background:
																	'#DFEBF080',
															}}
														>
															<XButtonImg
																style={{
																	background:
																		'white',
																	boxShadow:
																		'0px 0px 10px 0px #0000001A',
																}}
																disabled
																icon={
																	<img
																		src="/images/edit-icon.svg"
																		alt="Edit"
																	/>
																}
																className="me-3"
																type="text"
																onClick={() => {
																	form.setFieldsValue(
																		{
																			modal_text_area:
																				row,
																		},
																	);
																	setModalTextArea(
																		row,
																	);
																	setModalOpen(
																		[
																			true,
																			`Edit Past Clinical History`,
																			row,
																			index,
																			'pastHistory',
																		],
																	);
																}}
															></XButtonImg>
															<XButtonImg
																style={{
																	background:
																		'white',
																	boxShadow:
																		'0px 0px 10px 0px #0000001A',
																}}
																disabled
																icon={
																	<img
																		src="/images/delete-icon-new.svg"
																		alt="Delete"
																	/>
																}
																type="text"
																onClick={() => {
																	showDeleteConfirm(
																		'Past Clinical History',
																		index,
																		row,
																		'pastHistory',
																	);
																}}
															></XButtonImg>
														</Col>
													)}
												</Row>
											</XRecommendedCard>
										</>
									);
							  })
							: null}
						{currentRevision?.field !== 'past_history' &&
						pageContent.pastHistory.length > 0
							? pageContent.pastHistory.map((row, index) => {
									const splitPastHistory =
										row.split('- ICD-10 Code:');

									return (
										<>
											<XRecommendedMobCard
												bgColor="#ffffff"
												className="mt-2 showMobile"
											>
												<>
													<Row>
														<Col xs={22}>
															{splitPastHistory.length >
															1 ? (
																<div className="drowdownTitle mb-2">
																	{
																		splitPastHistory[0]
																	}
																</div>
															) : (
																<div className="drowdownTitle mb-2">
																	{row}
																</div>
															)}
														</Col>
														{!previewMode && (
															<Col
																xs={2}
																className="text-end"
															>
																<XMobDropdown.Button
																	type="text"
																	menu={{
																		items: [
																			{
																				label: 'Edit',
																				key: '1',
																				icon: (
																					<EditOutlined />
																				),
																				onClick:
																					() => {
																						form.setFieldsValue(
																							{
																								modal_text_area:
																									row,
																							},
																						);
																						setModalTextArea(
																							row,
																						);
																						setModalOpen(
																							[
																								true,
																								`Edit Past Clinical History`,
																								row,
																								index,
																								'pastHistory',
																							],
																						);
																					},
																			},
																			{
																				type: 'divider',
																			},
																			{
																				label: 'Delete',
																				key: '2',
																				icon: (
																					<DeleteOutlined />
																				),
																				onClick:
																					() => {
																						showDeleteConfirm(
																							'Past Clinical History',
																							index,
																							row,
																							'pastHistory',
																						);
																					},
																			},
																		],
																	}}
																	trigger={[
																		'click',
																	]}
																/>
															</Col>
														)}
														{splitPastHistory.length >
															1 && (
															<Col
																xs={24}
																onClick={() => {
																	handleCodeClick(
																		'ICD-10',
																		{
																			code: splitPastHistory[1],
																			description:
																				splitPastHistory[0],
																			codeTypeText:
																				'pastHistory',
																			buttontext:
																				'Add in Past',
																		},
																	);
																}}
																role="button"
															>
																<div className="drowdownSubTitle">
																	ICD-10:
																	{
																		splitPastHistory[1]
																	}
																</div>
															</Col>
														)}
													</Row>
												</>
											</XRecommendedMobCard>
											<XRecommendedCard
												bgColor="#ffffff"
												className="mt- showDesktop"
											>
												<Row className="align-items-center justify-content-between">
													<Col xs={24} lg={14}>
														{splitPastHistory.length >
														1 ? (
															<XPara1>
																{
																	splitPastHistory[0]
																}
															</XPara1>
														) : (
															<XPara1>
																{row}
															</XPara1>
														)}
													</Col>
													{splitPastHistory.length >
														1 && (
														<Col
															xs={24}
															lg={6}
															className="text-center"
															style={{
																borderLeft:
																	'1px solid #57636F26',
																borderRight:
																	'1px solid #57636F26',
															}}
															onClick={() => {
																handleCodeClick(
																	'ICD-10',
																	{
																		code: splitPastHistory[1],
																		description:
																			splitPastHistory[0],
																		codeTypeText:
																			'pastHistory',
																		buttontext:
																			'Add in Past',
																	},
																);
															}}
															role="button"
														>
															<XPara1>
																<span className="hoverGradient">
																	ICD-10:
																	{
																		splitPastHistory[1]
																	}
																</span>
															</XPara1>
														</Col>
													)}
													{!previewMode && (
														<Col
															xs={24}
															lg={4}
															className="text-center"
															style={{
																padding: '15px',
																background:
																	'#DFEBF080',
															}}
														>
															<XButtonImg
																style={{
																	background:
																		'white',
																	boxShadow:
																		'0px 0px 10px 0px #0000001A',
																}}
																icon={
																	<img
																		src="/images/edit-icon.svg"
																		alt="Edit"
																	/>
																}
																className="me-3"
																type="text"
																onClick={() => {
																	form.setFieldsValue(
																		{
																			modal_text_area:
																				row,
																		},
																	);
																	setModalTextArea(
																		row,
																	);
																	setModalOpen(
																		[
																			true,
																			`Edit Past Clinical History`,
																			row,
																			index,
																			'pastHistory',
																		],
																	);
																}}
															></XButtonImg>
															<XButtonImg
																style={{
																	background:
																		'white',
																	boxShadow:
																		'0px 0px 10px 0px #0000001A',
																}}
																icon={
																	<img
																		src="/images/delete-icon-new.svg"
																		alt="Delete"
																	/>
																}
																type="text"
																onClick={() => {
																	showDeleteConfirm(
																		'Past Clinical History',
																		index,
																		row,
																		'pastHistory',
																	);
																}}
															></XButtonImg>
														</Col>
													)}
												</Row>
											</XRecommendedCard>
										</>
									);
							  })
							: currentRevision?.field !== 'past_history'
							? '-'
							: ''}
					</XCardBackBg>

					{/* Present Clicnical History */}
					<XCardBackBg className="mt-3">
						<Row gutter={(15, 15)} className="align-items-center">
							<Col xs={20} lg={20}>
								<Space align="center">
									<img
										className="iconResp"
										src="/images/present-icon.svg"
										alt=""
									/>
									<XTitleHead>
										<div className="labelHeading color2">
											Present Complaint
										</div>
									</XTitleHead>
								</Space>
							</Col>
							{!previewMode && (
								<Col xs={4} lg={4} className="text-end">
									<XButtonAdd
										icon={<PlusOutlined />}
										onClick={() => {
											form.setFieldsValue({
												modal_text_area: '',
											});
											setModalTextArea('');
											setModalOpen([
												true,
												'Add Present Complaint',
												'',
												null,
												'presentComplaint',
											]);
										}}
									></XButtonAdd>
								</Col>
							)}
						</Row>
						<XDivider dashed className="mt-3  d-none d-md-block" />
						{currentRevision?.field === 'present_complaint' &&
						currentRevision?.finalValue &&
						currentRevision?.finalValue.length > 0
							? currentRevision?.finalValue.map((row, index) => {
									const splitPresentComplaint =
										row.value.split('- ICD-10 Code:');

									return (
										<>
											{/* //mew cards */}
											<XRecommendedMobCard
												bgColor="#ffffff"
												className="mt-2 showMobile"
												style={{
													backgroundColor:
														row.type === 'added'
															? '#eaebfb'
															: row.type ===
															  'removed'
															? 'rgb(255, 236, 236)'
															: '#ffffff',
												}}
											>
												<>
													<Row>
														<Col xs={22}>
															{splitPresentComplaint.length >
															1 ? (
																<div className="drowdownTitle mb-2">
																	{
																		splitPresentComplaint[0]
																	}
																</div>
															) : (
																<div className="drowdownTitle mb-2">
																	{row.value}
																</div>
															)}
														</Col>
														{!previewMode && (
															<Col
																xs={2}
																className="text-end"
															>
																<XMobDropdown.Button
																	type="text"
																	disabled
																	menu={{
																		items: [
																			{
																				label: 'Edit',
																				key: '1',
																				icon: (
																					<EditOutlined />
																				),
																				onClick:
																					() => {
																						form.setFieldsValue(
																							{
																								modal_text_area:
																									row,
																							},
																						);
																						setModalTextArea(
																							row,
																						);
																						setModalOpen(
																							[
																								true,
																								`Edit Present Complaint`,
																								row,
																								index,
																								'presentComplaint',
																							],
																						);
																					},
																			},
																			{
																				type: 'divider',
																			},
																			{
																				label: 'Delete',
																				key: '2',
																				icon: (
																					<DeleteOutlined />
																				),
																				onClick:
																					() => {
																						showDeleteConfirm(
																							'Present Complaint',
																							index,
																							row,
																							'presentComplaint',
																						);
																					},
																			},
																		],
																	}}
																	trigger={[
																		'click',
																	]}
																/>
															</Col>
														)}
														{splitPresentComplaint.length >
															1 && (
															<Col
																xs={24}
																style={{
																	borderLeft:
																		'1px solid #57636F26',
																	borderRight:
																		'1px solid #57636F26',
																}}
																onClick={() => {
																	handleCodeClick(
																		'ICD-10',
																		{
																			code: splitPresentComplaint[1],
																			description:
																				splitPresentComplaint[0],
																			codeTypeText:
																				'presentComplaint',
																			buttontext:
																				'Add in Present',
																		},
																	);
																}}
																role="button"
															>
																<div className="drowdownSubTitle">
																	ICD-10:
																	{
																		splitPresentComplaint[1]
																	}
																</div>
															</Col>
														)}
													</Row>
												</>
											</XRecommendedMobCard>

											{/* desktop */}
											<XRecommendedCard
												bgColor="#ffffff"
												className="mt-3 showDesktop"
												style={{
													backgroundColor:
														row.type === 'added'
															? '#eaebfb'
															: row.type ===
															  'removed'
															? 'rgb(255, 236, 236)'
															: '#ffffff',
												}}
											>
												<Row className="align-items-center justify-content-between">
													<Col xs={24} lg={14}>
														{splitPresentComplaint.length >
														1 ? (
															<XPara1>
																{
																	splitPresentComplaint[0]
																}
															</XPara1>
														) : (
															<XPara1>
																{row.value}
															</XPara1>
														)}
													</Col>
													{splitPresentComplaint.length >
														1 && (
														<Col
															xs={24}
															lg={6}
															className="text-center"
															style={{
																borderLeft:
																	'1px solid #57636F26',
																borderRight:
																	'1px solid #57636F26',
															}}
															onClick={() => {
																handleCodeClick(
																	'ICD-10',
																	{
																		code: splitPresentComplaint[1],
																		description:
																			splitPresentComplaint[0],
																		codeTypeText:
																			'presentComplaint',
																		buttontext:
																			'Add in Present',
																	},
																);
															}}
															role="button"
														>
															<XPara1>
																<span className="hoverGradient">
																	ICD-10:
																	{
																		splitPresentComplaint[1]
																	}
																</span>
															</XPara1>
														</Col>
													)}
													{!previewMode && (
														<Col
															xs={24}
															lg={4}
															className="text-center"
															style={{
																padding: '15px',
																background:
																	'#DFEBF080',
															}}
														>
															<XButtonImg
																style={{
																	background:
																		'white',
																	boxShadow:
																		'0px 0px 10px 0px #0000001A',
																}}
																icon={
																	<img
																		src="/images/edit-icon.svg"
																		alt="Edit"
																	/>
																}
																className="me-3"
																type="text"
																onClick={() => {
																	form.setFieldsValue(
																		{
																			modal_text_area:
																				row,
																		},
																	);
																	setModalTextArea(
																		row,
																	);
																	setModalOpen(
																		[
																			true,
																			`Edit Present Complaint`,
																			row,
																			index,
																			'presentComplaint',
																		],
																	);
																}}
															></XButtonImg>
															<XButtonImg
																style={{
																	background:
																		'white',
																	boxShadow:
																		'0px 0px 10px 0px #0000001A',
																}}
																icon={
																	<img
																		src="/images/delete-icon-new.svg"
																		alt="Delete"
																	/>
																}
																type="text"
																onClick={() => {
																	showDeleteConfirm(
																		'Present Complaint',
																		index,
																		row,
																		'presentComplaint',
																	);
																}}
															></XButtonImg>
														</Col>
													)}
												</Row>
											</XRecommendedCard>
										</>
									);
							  })
							: null}
						{currentRevision?.field !== 'present_complaint' &&
						pageContent.presentComplaint.length > 0
							? pageContent.presentComplaint.map((row, index) => {
									const splitPresentComplaint =
										row.split('- ICD-10 Code:');

									return (
										<>
											<XRecommendedMobCard
												bgColor="#ffffff"
												className="mt-2 showMobile"
											>
												<>
													<Row>
														<Col xs={22}>
															{splitPresentComplaint.length >
															1 ? (
																<div className="drowdownTitle mb-2">
																	{
																		splitPresentComplaint[0]
																	}
																</div>
															) : (
																<div className="drowdownTitle mb-2">
																	{row}
																</div>
															)}
														</Col>
														{!previewMode && (
															<Col
																xs={2}
																className="text-end"
															>
																<XMobDropdown.Button
																	type="text"
																	menu={{
																		items: [
																			{
																				label: 'Edit',
																				key: '1',
																				icon: (
																					<EditOutlined />
																				),
																				onClick:
																					() => {
																						form.setFieldsValue(
																							{
																								modal_text_area:
																									row,
																							},
																						);
																						setModalTextArea(
																							row,
																						);
																						setModalOpen(
																							[
																								true,
																								`Edit Present Complaint`,
																								row,
																								index,
																								'presentComplaint',
																							],
																						);
																					},
																			},
																			{
																				type: 'divider',
																			},
																			{
																				label: 'Delete',
																				key: '2',
																				icon: (
																					<DeleteOutlined />
																				),
																				onClick:
																					() => {
																						showDeleteConfirm(
																							'Present Complaint',
																							index,
																							row,
																							'presentComplaint',
																						);
																					},
																			},
																		],
																	}}
																	trigger={[
																		'click',
																	]}
																/>
															</Col>
														)}
														{splitPresentComplaint.length >
															1 && (
															<Col
																xs={24}
																onClick={() => {
																	handleCodeClick(
																		'ICD-10',
																		{
																			code: splitPresentComplaint[1],
																			description:
																				splitPresentComplaint[0],
																			codeTypeText:
																				'presentComplaint',
																			buttontext:
																				'Add in Present',
																		},
																	);
																}}
																role="button"
															>
																<div className="drowdownSubTitle">
																	ICD-10:
																	{
																		splitPresentComplaint[1]
																	}
																</div>
															</Col>
														)}
													</Row>
												</>
											</XRecommendedMobCard>

											<XRecommendedCard
												bgColor="#ffffff"
												className="mt-3 showDesktop"
											>
												<Row className="align-items-center justify-content-between">
													<Col xs={24} lg={14}>
														{splitPresentComplaint.length >
														1 ? (
															<XPara1>
																{
																	splitPresentComplaint[0]
																}
															</XPara1>
														) : (
															<XPara1>
																{row}
															</XPara1>
														)}
													</Col>
													{splitPresentComplaint.length >
														1 && (
														<Col
															xs={24}
															lg={6}
															className="text-center"
															style={{
																borderLeft:
																	'1px solid #57636F26',
																borderRight:
																	'1px solid #57636F26',
															}}
															onClick={() => {
																handleCodeClick(
																	'ICD-10',
																	{
																		code: splitPresentComplaint[1],
																		description:
																			splitPresentComplaint[0],
																		codeTypeText:
																			'presentComplaint',
																		buttontext:
																			'Add in Present',
																	},
																);
															}}
															role="button"
														>
															<XPara1>
																<span className="hoverGradient">
																	ICD-10:
																	{
																		splitPresentComplaint[1]
																	}
																</span>
															</XPara1>
														</Col>
													)}
													{!previewMode && (
														<Col
															xs={24}
															lg={4}
															className="text-center"
															style={{
																padding: '15px',
																background:
																	'#DFEBF080',
															}}
														>
															<XButtonImg
																style={{
																	background:
																		'white',
																	boxShadow:
																		'0px 0px 10px 0px #0000001A',
																}}
																icon={
																	<img
																		src="/images/edit-icon.svg"
																		alt="Edit"
																	/>
																}
																className="me-3"
																type="text"
																onClick={() => {
																	form.setFieldsValue(
																		{
																			modal_text_area:
																				row,
																		},
																	);
																	setModalTextArea(
																		row,
																	);
																	setModalOpen(
																		[
																			true,
																			`Edit Present Complaint`,
																			row,
																			index,
																			'presentComplaint',
																		],
																	);
																}}
															></XButtonImg>
															<XButtonImg
																style={{
																	background:
																		'white',
																	boxShadow:
																		'0px 0px 10px 0px #0000001A',
																}}
																icon={
																	<img
																		src="/images/delete-icon-new.svg"
																		alt="Delete"
																	/>
																}
																type="text"
																onClick={() => {
																	showDeleteConfirm(
																		'Present Complaint',
																		index,
																		row,
																		'presentComplaint',
																	);
																}}
															></XButtonImg>
														</Col>
													)}
												</Row>
											</XRecommendedCard>
										</>
									);
							  })
							: currentRevision?.field !== 'present_complaint'
							? '-'
							: ''}
					</XCardBackBg>
				</>
			)}

			{checkCoverage && policyType !== 'commercial' && (
				<>
					{/* Missing Fields and Recommended Procedure */}
					<XCardBackBg
						ref={missingFieldsCardRef}
						className={`mt-3 ${
							isReclaimPage ? 'reclaimClass' : ''
						}`}
					>
						<Row gutter={(15, 15)} className="align-items-center">
							<Col xs={20} lg={20}>
								<Space align="center">
									<img
										className="iconResp"
										src="/images/procedure-icon.svg"
										alt=""
									/>
									<XTitleHead>
										<div class="labelHeading color3">
											Denial Procedure
										</div>
									</XTitleHead>
								</Space>
							</Col>
							{!missingFieldsModal && !previewMode && (
								<Col xs={4} lg={4} className="text-end">
									<XButtonAdd
										icon={<PlusOutlined />}
										onClick={() => {
											form.setFieldsValue({
												modal_text_area: '',
											});
											setModalTextArea('');
											setModalOpen([
												true,
												'Add Denial Procedure',
												'',
												null,
												'recommendedProcedure',
											]);
										}}
									></XButtonAdd>
								</Col>
							)}
						</Row>
						<XDivider dashed className="mt-3 d-none d-md-block" />
						{missingFieldsModal ? (
							<>
								<XModalTitle className="ms-2 mt-4">
									Provide us with the below details for
									completing the analysis
								</XModalTitle>
								{missingFieldTitle && (
									<XModalSubtitle className="ms-2 mt-2">
										{missingFieldTitle}
									</XModalSubtitle>
								)}
								<XDivider dashed className="mt-3" />
								<XForm
									form={missingFieldsForm}
									onFinish={() => {
										missingFieldsForm
											.validateFields()
											.then((values) => {
												setLoading(true);
												reviseCoverageReset();
												reviseCoverage({
													// case_id: patientData._id,
													// analysis_id: id,
													feedbackId: feedbackId,
													clinicalHistory:
														pageContent.pastHistory,
													presentComplaint:
														pageContent.presentComplaint,
													recommendedProcedure:
														pageContent.recommendedProcedure,
													medicalCoverage:
														pageContent.medicalCoverage,
													state: isOpenAnalysis
														? state
														: patientData.state,
													provider: isOpenAnalysis
														? provider
														: patientData
																.insurance_company
																?.name,
													missing_fields: values,
													last_analysis_data:
														lastAnalysisData,
													supportingDocsSummary:
														isOpenAnalysis
															? supportingDocsSummary
															: patientData?.summary_docs,
													...(isOpenAnalysis
														? { analysis_id: id } // If isOpenAnalysis is true, include analysis_id
														: {
																case_id:
																	patientData._id,
														  }),
												});

												missingFieldsForm.resetFields();
												setMissingFields([]);
												setMissingFieldInstructions([]);
												setMissingFieldsModal(false);
											})
											.catch((error) => {
												return;
											});
									}}
									name="groupForm"
									layout="vertical"
									autoComplete="off"
								>
									<Row gutter={(15, 0)} className="mt-4">
										{missingFieldInstructions.length >
											0 && (
											<Col xs={24}>
												<XList
													className={
														isReclaimPage
															? 'reclaimInstHead'
															: ''
													}
													size="small"
													header={
														<div>Instructions</div>
													}
													bordered
													dataSource={
														missingFieldInstructions
													}
													renderItem={(
														item,
														index,
													) => (
														<List.Item>
															<XCircle>
																{index + 1}
															</XCircle>{' '}
															{item}
														</List.Item>
													)}
												/>
												<br />
											</Col>
										)}

										{missingFields.map((item, index) => {
											if (item.type == 'textarea') {
												return (
													<Col xs={24}>
														<XForm.Item
															label={
																<XLabel>
																	{item.label}
																</XLabel>
															}
															name={item.name}
															rules={[
																item.hasOwnProperty(
																	'required',
																) &&
																	item.required && {
																		required: true,
																		message:
																			item.required_error_message ??
																			'Field is required',
																	},
																item.hasOwnProperty(
																	'regex',
																) &&
																	item.regex && {
																		validator:
																			(
																				rule,
																				value,
																			) => {
																				let regex =
																					new RegExp(
																						item.regex.slice(
																							1,
																							-1,
																						),
																					);
																				if (
																					!regex.test(
																						value,
																					)
																				)
																					return Promise.reject(
																						new Error(
																							item.regex_error_message ??
																								'Invalid Data',
																						),
																					);
																				return Promise.resolve();
																			},
																	},
															]}
														>
															<TextArea
																rows={3}
																name={item.name}
																showCount
																className="textArea"
																placeholder="Enter"
															></TextArea>
														</XForm.Item>
													</Col>
												);
											} else if (item.type == 'input') {
												return (
													<Col xs={24}>
														<XForm.Item
															label={
																<XLabel>
																	{item.label}
																</XLabel>
															}
															name={item.name}
															rules={[
																item.hasOwnProperty(
																	'required',
																) &&
																	item.required && {
																		required: true,
																		message:
																			item.required_error_message ??
																			'Field is required',
																	},
																item.hasOwnProperty(
																	'regex',
																) &&
																	item.regex && {
																		validator:
																			(
																				rule,
																				value,
																			) => {
																				let regex =
																					new RegExp(
																						item.regex.slice(
																							1,
																							-1,
																						),
																					);
																				if (
																					!regex.test(
																						value,
																					)
																				)
																					return Promise.reject(
																						new Error(
																							item.regex_error_message ??
																								'Invalid Data',
																						),
																					);
																				return Promise.resolve();
																			},
																	},
															]}
														>
															<Input
																size="large"
																name={item.name}
																placeholder="Enter"
															></Input>
														</XForm.Item>
													</Col>
												);
											} else if (
												item.type == 'checkbox'
											) {
												return (
													<>
														<Col
															xs={24}
															key={index}
														>
															<XForm.Item
																name={item.name}
																rules={[
																	item.required && {
																		required: true,
																		message:
																			item.required_error_message ??
																			'Field is required',
																	},
																]}
															>
																<XCheckboxGroup
																	name={
																		item.name
																	}
																	style={{
																		display:
																			'flex',
																		flexDirection:
																			'column',
																	}}
																>
																	<XList
																		className={
																			isReclaimPage
																				? 'reclaimInstHead'
																				: ''
																		}
																		size="small"
																		bordered
																		header={
																			<div
																				style={{
																					display:
																						'flex',
																					justifyContent:
																						'space-between',
																					alignItems:
																						'center',
																				}}
																			>
																				<XLabel>
																					{
																						item.label
																					}
																				</XLabel>
																				<XRoundButton
																					shape="circle"
																					icon={
																						<PlusOutlined />
																					}
																					onClick={() =>
																						handleAddOption(
																							index,
																						)
																					}
																				/>
																			</div>
																		}
																		dataSource={
																			item.options
																		}
																		renderItem={(
																			option,
																			idx,
																		) => (
																			<List.Item>
																				<Checkbox
																					value={
																						option.value
																					}
																				>
																					{
																						option.label
																					}
																				</Checkbox>
																			</List.Item>
																		)}
																	/>
																</XCheckboxGroup>
															</XForm.Item>
														</Col>
													</>
												);
											} else if (item.type == 'radio') {
												return (
													<Col xs={24} key={index}>
														<XForm.Item
															name={item.name}
															rules={[
																item.required && {
																	required: true,
																	message:
																		'Field is required',
																},
															]}
														>
															<Radio.Group
																style={{
																	display:
																		'flex',
																	flexDirection:
																		'column',
																}}
															>
																<XList
																	className={
																		isReclaimPage
																			? 'reclaimInstHead'
																			: 'normal-header'
																	}
																	size="small"
																	bordered
																	header={
																		<div
																			style={{
																				display:
																					'flex',
																				justifyContent:
																					'space-between',
																				alignItems:
																					'center',
																			}}
																		>
																			<XLabel>
																				{
																					item.label
																				}
																			</XLabel>
																		</div>
																	}
																	dataSource={
																		item.options
																	}
																	renderItem={(
																		option,
																		idx,
																	) => (
																		<List.Item>
																			<Radio
																				value={
																					option.value
																				}
																			>
																				{
																					option.label
																				}
																			</Radio>
																		</List.Item>
																	)}
																/>
															</Radio.Group>
														</XForm.Item>
													</Col>
												);
											}
										})}
									</Row>
									<Row className="mt-1">
										<Col xs={24} className="text-end">
											<XButtonConfirm
												type="primary"
												style={{
													background: '#007299',
													color: '#fff',
												}}
												htmlType="submit"
												className="modalButton"
												loading={''}
											>
												Submit
											</XButtonConfirm>
										</Col>
									</Row>
								</XForm>
							</>
						) : currentRevision?.field !== 'suggested_treatment' &&
						  pageContent.recommendedProcedure.length > 0 ? (
							pageContent.recommendedProcedure.map(
								(row, index) => {
									const splitRecommendedProcedure =
										row.split('- CPT Code:');

									return (
										<>
											<XRecommendedMobCard
												bgColor="#f5f6ff"
												className="mt-2 showMobile"
											>
												<>
													<Row className="align-items-center">
														<Col xs={22}>
															{splitRecommendedProcedure.length >
															1 ? (
																<div className="drowdownTitle mb-2">
																	{
																		splitRecommendedProcedure[0]
																	}
																</div>
															) : (
																<div className="drowdownTitle mb-2">
																	{row}
																</div>
															)}
														</Col>
														{!previewMode && (
															<Col
																xs={2}
																className="text-end"
															>
																<XMobDropdown.Button
																	type="text"
																	menu={{
																		items: [
																			{
																				label: 'Edit',
																				key: '1',
																				icon: (
																					<EditOutlined />
																				),
																				onClick:
																					() => {
																						form.setFieldsValue(
																							{
																								modal_text_area:
																									row,
																							},
																						);
																						setModalTextArea(
																							row,
																						);
																						setModalOpen(
																							[
																								true,
																								`Edit Denial Procedure`,
																								row,
																								index,
																								'recommendedProcedure',
																							],
																						);
																					},
																			},
																			{
																				type: 'divider',
																			},
																			{
																				label: 'Delete',
																				key: '2',
																				icon: (
																					<DeleteOutlined />
																				),
																				onClick:
																					() => {
																						showDeleteConfirm(
																							'Denial Procedure',
																							index,
																							row,
																							'recommendedProcedure',
																						);
																					},
																			},
																		],
																	}}
																	trigger={[
																		'click',
																	]}
																/>
															</Col>
														)}
														{splitRecommendedProcedure.length >
															1 && (
															<Col
																xs={24}
																onClick={() => {
																	handleCodeClick(
																		'CPT',
																		{
																			code: splitRecommendedProcedure[1],
																			description:
																				splitRecommendedProcedure[0],
																			codeTypeText:
																				'recommendedProcedure',
																			buttontext:
																				'Add in Denial',
																		},
																	);
																}}
																role="button"
															>
																<div className="drowdownSubTitle">
																	CPT:
																	{
																		splitRecommendedProcedure[1]
																	}
																</div>
															</Col>
														)}
													</Row>
												</>
											</XRecommendedMobCard>

											<XRecommendedCard
												bgColor="#f5f6ff"
												className="mt-3 showDesktop"
											>
												<Row className="align-items-center justify-content-between">
													<Col xs={24} lg={14}>
														{splitRecommendedProcedure.length >
														1 ? (
															<XPara1>
																{
																	splitRecommendedProcedure[0]
																}
															</XPara1>
														) : (
															<XPara1>
																{row}
															</XPara1>
														)}
													</Col>
													{splitRecommendedProcedure.length >
														1 && (
														<Col
															xs={24}
															lg={6}
															className="text-center"
															style={{
																borderLeft:
																	'1px solid #57636F26',
																borderRight:
																	'1px solid #57636F26',
															}}
															onClick={() => {
																handleCodeClick(
																	'CPT',
																	{
																		code: splitRecommendedProcedure[1],
																		description:
																			splitRecommendedProcedure[0],
																		codeTypeText:
																			'recommendedProcedure',
																		buttontext:
																			'Add in Denial',
																	},
																);
															}}
															role="button"
														>
															<XPara1>
																<span className="hoverGradient">
																	CPT:
																	{
																		splitRecommendedProcedure[1]
																	}
																</span>
															</XPara1>
														</Col>
													)}
													{!previewMode && (
														<Col
															xs={24}
															lg={4}
															className="text-center"
															style={{
																padding: '15px',
																background:
																	'#DFEBF080',
															}}
														>
															<XButtonImg
																style={{
																	background:
																		'white',
																	boxShadow:
																		'0px 0px 10px 0px #0000001A',
																}}
																icon={
																	<img
																		src="/images/edit-icon.svg"
																		alt="Edit"
																	/>
																}
																className="me-3"
																type="text"
																onClick={() => {
																	form.setFieldsValue(
																		{
																			modal_text_area:
																				row,
																		},
																	);
																	setModalTextArea(
																		row,
																	);
																	setModalOpen(
																		[
																			true,
																			`Edit Denial Procedure`,
																			row,
																			index,
																			'recommendedProcedure',
																		],
																	);
																}}
															></XButtonImg>
															<XButtonImg
																style={{
																	background:
																		'white',
																	boxShadow:
																		'0px 0px 10px 0px #0000001A',
																}}
																icon={
																	<img
																		src="/images/delete-icon-new.svg"
																		alt="Delete"
																	/>
																}
																type="text"
																onClick={() => {
																	showDeleteConfirm(
																		'Denial Procedure',
																		index,
																		row,
																		'recommendedProcedure',
																	);
																}}
															></XButtonImg>
														</Col>
													)}
												</Row>
											</XRecommendedCard>
										</>
									);
								},
							)
						) : currentRevision?.field === 'suggested_treatment' &&
						  currentRevision?.finalValue &&
						  currentRevision?.finalValue.length > 0 ? (
							currentRevision?.finalValue.map((row, index) => {
								const splitRecommendedProcedure =
									row.value.split('- CPT Code:');

								return (
									<>
										{/* new */}
										<XRecommendedMobCard
											bgColor="#f5f6ff"
											className="mt-2 showMobile"
											style={{
												backgroundColor:
													row.type === 'added'
														? '#eaebfb'
														: row.type === 'removed'
														? 'rgb(255, 236, 236)'
														: '#ffffff',
											}}
										>
											<>
												<Row className="align-items-center">
													<Col xs={22}>
														{splitRecommendedProcedure.length >
														1 ? (
															<div className="drowdownTitle mb-2">
																{
																	splitRecommendedProcedure[0]
																}
															</div>
														) : (
															<div className="drowdownTitle mb-2">
																{row.value}
															</div>
														)}
													</Col>
													{!previewMode && (
														<Col
															xs={2}
															className="text-end"
														>
															<XMobDropdown.Button
																type="text"
																disabled
																menu={{
																	items: [
																		{
																			label: 'Edit',
																			key: '1',
																			icon: (
																				<EditOutlined />
																			),
																			onClick:
																				() => {
																					form.setFieldsValue(
																						{
																							modal_text_area:
																								row,
																						},
																					);
																					setModalTextArea(
																						row,
																					);
																					setModalOpen(
																						[
																							true,
																							`Edit Denial Procedure`,
																							row,
																							index,
																							'recommendedProcedure',
																						],
																					);
																				},
																		},
																		{
																			type: 'divider',
																		},
																		{
																			label: 'Delete',
																			key: '2',
																			icon: (
																				<DeleteOutlined />
																			),
																			onClick:
																				() => {
																					showDeleteConfirm(
																						'Denial Procedure',
																						index,
																						row,
																						'recommendedProcedure',
																					);
																				},
																		},
																	],
																}}
																trigger={[
																	'click',
																]}
															/>
														</Col>
													)}
													{splitRecommendedProcedure.length >
														1 && (
														<Col
															xs={24}
															onClick={() => {
																handleCodeClick(
																	'CPT',
																	{
																		code: splitRecommendedProcedure[1],
																		description:
																			splitRecommendedProcedure[0],
																		codeTypeText:
																			'recommendedProcedure',
																		buttontext:
																			'Add in Denial',
																	},
																);
															}}
															role="button"
														>
															<div className="drowdownSubTitle">
																CPT:
																{
																	splitRecommendedProcedure[1]
																}
															</div>
														</Col>
													)}
												</Row>
											</>
										</XRecommendedMobCard>

										<XRecommendedCard
											bgColor="#f5f6ff"
											className="mt-3 showDesktop"
											style={{
												backgroundColor:
													row.type === 'added'
														? '#eaebfb'
														: row.type === 'removed'
														? 'rgb(255, 236, 236)'
														: '#ffffff',
											}}
										>
											<Row className="align-items-center justify-content-between">
												<Col xs={24} lg={14}>
													{splitRecommendedProcedure.length >
													1 ? (
														<XPara1>
															{
																splitRecommendedProcedure[0]
															}
														</XPara1>
													) : (
														<XPara1>
															{row.value}
														</XPara1>
													)}
												</Col>
												{splitRecommendedProcedure.length >
													1 && (
													<Col
														xs={24}
														lg={6}
														className="text-center"
														style={{
															borderLeft:
																'1px solid #57636F26',
															borderRight:
																'1px solid #57636F26',
														}}
														onClick={() => {
															handleCodeClick(
																'CPT',
																{
																	code: splitRecommendedProcedure[1],
																	description:
																		splitRecommendedProcedure[0],
																	codeTypeText:
																		'recommendedProcedure',
																	buttontext:
																		'Add in Denial',
																},
															);
														}}
														role="button"
													>
														<XPara1>
															<span className="hoverGradient">
																CPT:
																{
																	splitRecommendedProcedure[1]
																}
															</span>
														</XPara1>
													</Col>
												)}
												{!previewMode && (
													<Col
														xs={24}
														lg={4}
														className="text-center"
														style={{
															padding: '15px',
															background:
																'#DFEBF080',
														}}
													>
														<XButtonImg
															style={{
																background:
																	'white',
																boxShadow:
																	'0px 0px 10px 0px #0000001A',
															}}
															disabled
															icon={
																<img
																	src="/images/edit-icon.svg"
																	alt="Edit"
																/>
															}
															className="me-3"
															type="text"
															onClick={() => {
																form.setFieldsValue(
																	{
																		modal_text_area:
																			row,
																	},
																);
																setModalTextArea(
																	row,
																);
																setModalOpen([
																	true,
																	`Edit Denial Procedure`,
																	row,
																	index,
																	'recommendedProcedure',
																]);
															}}
														></XButtonImg>
														<XButtonImg
															style={{
																background:
																	'white',
																boxShadow:
																	'0px 0px 10px 0px #0000001A',
															}}
															disabled
															icon={
																<img
																	src="/images/delete-icon-new.svg"
																	alt="Delete"
																/>
															}
															type="text"
															onClick={() => {
																showDeleteConfirm(
																	'Denial Procedure',
																	index,
																	row,
																	'recommendedProcedure',
																);
															}}
														></XButtonImg>
													</Col>
												)}
											</Row>
										</XRecommendedCard>
									</>
								);
							})
						) : (
							'-'
						)}
					</XCardBackBg>
				</>
			)}

			{/* Medical Coverage */}
			<XCardBackBg
				className={`mt-3 ${isReclaimPage ? 'reclaimClass' : ''}`}
			>
				<Row gutter={(15, 15)} className="align-items-center">
					<Col xs={24}>
						<Space align="center">
							<img
								style={{
									width: '40px',
								}}
								src={
									loading
										? '/images/no-cross-icon.svg'
										: missingFieldsModal
										? '/images/black-cross-icon.svg'
										: pageContent.medicalCoverage[0] ===
										  'Yes'
										? '/images/coverage-icon.svg'
										: '/images/cross-icon.svg'
								}
								alt=""
							/>
							<XTitleHead>
								<div
									className={`labelHeading ${
										loading
											? 'color6'
											: missingFieldsModal
											? 'color6'
											: pageContent.medicalCoverage[0] ===
											  'Yes'
											? 'color4'
											: 'color5'
									}`}
								>
									Medicare Coverage
								</div>
							</XTitleHead>
						</Space>
					</Col>
				</Row>
				{pageContent.medicalCoverage.length > 0 ? (
					<>
						<Row gutter={(15, 15)} className="align-items-center">
							<Col xs={24}>
								<XPara1 style={{ textAlign: 'justify' }}>
									{missingFieldsModal &&
										'Action Awaited on the Above Item'}
									{pageContent.medicalCoverage[1]}
									{pageContent.medicalCoverage[2] &&
									Object.keys(pageContent.medicalCoverage[2])
										.length > 0 ? (
										<>
											<br />
											<br />
											<i>
												Reference:{' '}
												<a
													href={
														pageContent
															.medicalCoverage[2][
															'target'
														]
													}
													rel="noreferrer"
													target="_blank"
												>
													{
														pageContent
															.medicalCoverage[2][
															'display_text'
														]
													}
												</a>
											</i>
										</>
									) : (
										''
									)}
								</XPara1>
							</Col>
						</Row>
					</>
				) : (
					''
				)}
			</XCardBackBg>

			{/* Missing Coverage */}
			{pageContent.missing_points_for_coverage &&
				pageContent.missing_points_for_coverage.length > 0 && (
					<>
						<XCardBackBg className="mt-3">
							<Row>
								<Col xs={24}>
									<Space align="center">
										<img
											src={'/images/criteria.svg'}
											alt=""
										/>
										<XTitleHead>
											<div class="labelHeading color7">
												Missing Inclusion Criteria
												<Tooltip title="This feature is experimental and may contain errors as it evolves. Please exercise discretion, especially when reviewing critical information.">
													<Tag
														style={{
															marginLeft: '15px',
															border: '1px solid #9B68BF',
															color: '#9B68BF',
															fontSize: '11px',
														}}
													>
														Alpha
													</Tag>
												</Tooltip>
											</div>
											{/* {policyType == 'commercial' &&
												pageContent
													.medicalCoverage[0] ===
													'No' && (
													<>
														<Col
															xs={24}
															className="text-end"
														>
															<XButtonConfirm
																type="primary"
																style={{
																	background:
																		'#007299',
																	color: '#fff',
																}}
																htmlType="submit"
																className="modalButton"
																loading={''}
															>
																Force Generate
															</XButtonConfirm>
														</Col>
													</>
												)} */}
										</XTitleHead>
									</Space>
								</Col>
							</Row>
							<Row
								gutter={(15, 15)}
								className="align-items-center criteria_sub_list"
							>
								<Col xs={24}>
									<ul className="listCircule">
										{pageContent.missing_points_for_coverage?.map(
											(row, index) => {
												return <li>{row}</li>;
											},
										)}
									</ul>
								</Col>
							</Row>
						</XCardBackBg>
					</>
				)}

			{/* new modal start */}
			{/* uplodoaded Files Previews */}
			<Modal
				open={previewOpen}
				title={previewTitle}
				footer={null}
				onCancel={() => setPreviewOpen(false)}
			>
				{previewImage ? (
					<img
						alt="example"
						style={{
							width: '100%',
						}}
						src={previewImage}
					/>
				) : null}
			</Modal>
			{/* Modal for uplodoaded Files preview */}
			<Modal
				open={pdfPreviewOpen}
				title="Preview"
				footer={null}
				onCancel={() => setPdfPreviewOpen(false)}
				width={pdfWidth} // Set the width of the modal dynamically
				// Other modal props...
			>
				<div>
					<Worker workerUrl="https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js">
						<div style={{ height: '750px' }}>
							<Viewer fileUrl={previewImage} />
						</div>
					</Worker>
				</div>
			</Modal>

			<XModal
				title={
					<>
						<EditOutlined />
						<span className="ms-2">{modalOpen[1]}</span>
					</>
				}
				open={modalOpen[0]}
				onOk={() => {
					form.validateFields()
						.then((res) => {
							if (modalOpen[4] === 'feedbackSummary') {
								sendFeedback({
									feedback_id: feedbackId,
									feedback: 'negative',
									status: 'pending_for_analysis',
									response: [coverage, modalTextArea],
								});
							} else {
								// Format the text according to the selected code type (ICD-10 or CPT)
								const formattedText = `${
									res.modal_text_area
								} - ${
									modalOpen[4] === 'recommendedProcedure'
										? 'CPT Code'
										: 'ICD-10 Code'
								}: ${res.code}`;
								// Save the formatted text in setModalTextArea
								setModalTextArea(formattedText);

								// Optionally update page content
								updatePageContent(
									modalOpen[4],
									modalOpen[3],
									formattedText,
								);

								// updatePageContent(
								// 	modalOpen[4],
								// 	modalOpen[3],
								// 	modalTextArea,
								// );
							}
						})
						.catch((err) => {
							console.log('error', err);
						});
				}}
				okButtonProps={{
					loading: clinicalDocsLoading,
					style: {
						backgroundColor: isReclaimPage ? '#0E4C88' : '#007299',
						borderColor: isReclaimPage ? '#0E4C88' : '#007299',
						color: '#fff',
						borderRadius: '20px',
					},
				}}
				cancelButtonProps={{
					style: {
						backgroundColor: '#fff',
						color: isReclaimPage ? '#0E4C88' : '#007299',
						borderRadius: '20px',
					},
				}}
				onCancel={() => {
					form.resetFields();
					setModalTextArea('');
					setModalOpen([false, '', ' ']);
				}}
				className="modalButton"
				okText="Submit"
			>
				<XForm
					form={form}
					name="groupForm"
					layout="vertical"
					autoComplete="off"
				>
					<Row gutter={(15, 0)} className="mt-4">
						<Col xs={24}>
							{modalOpen[4] === 'feedbackSummary' && (
								<>
									<XForm.Item
										name="medicare_flag"
										label="Coverage"
										rules={[
											{
												required: true,
												message: 'Field is required',
											},
										]}
									>
										<Select
											showSearch
											placeholder="Select a person"
											optionFilterProp="children"
											onChange={(e) => {
												setCoverage(e);
											}}
											options={[
												{
													value: 'Yes',
													label: 'YES',
												},
												{
													value: 'No',
													label: 'NO',
												},
											]}
										/>
									</XForm.Item>

									<XForm.Item
										name="modal_text_area"
										rules={[
											{
												required: true,
												message: 'Field is required',
											},
										]}
									>
										<TextArea
											rows={6}
											name="modal_text_area"
											showCount
											style={{
												height: 120,
												marginBottom: 24,
											}}
											className="textArea mt-2"
											placeholder="Enter"
											onChange={(e) =>
												setModalTextArea(e.target.value)
											}
										>
											{/* modalOpen[2] */}
										</TextArea>
									</XForm.Item>
								</>
							)}
							{modalOpen[4] !== 'feedbackSummary' && (
								<>
									<XForm.Item
										name="modal_text_area"
										label="Description"
										rules={[
											{
												required: true,
												message:
													'Description is required',
											},
										]}
									>
										<TextArea
											name="modal_text_area"
											showCount
											style={{
												height: 100,
												// marginBottom: 24,
											}}
											className="textArea mt-2"
											placeholder="Enter description"
										/>
									</XForm.Item>
									<XForm.Item
										name="code"
										label={`${
											modalOpen[4] ===
											'recommendedProcedure'
												? 'CPT'
												: 'ICD-10'
										} Code`}
										rules={[
											{
												required: true,
												message: 'Code is required',
											},
										]}
									>
										<Input
											className="textArea mt-2"
											placeholder={`Enter ${
												modalOpen[4] ===
												'recommendedProcedure'
													? 'CPT'
													: 'ICD-10'
											} Code`}
										/>
									</XForm.Item>
								</>
							)}
						</Col>
					</Row>
				</XForm>
			</XModal>
			{/* new modal end */}
		</>
	);
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CaseOperations);
