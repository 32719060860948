import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
	XTag,
	XForm,
	XModal,
	XContainer,
	FilterButton,
	XSelect,
	XSearch,
} from '../../styles/GlobalStyle';
import HeaderFile from '../../components/Header/HeaderFile';
import moment from 'moment';
import { XButtonClose, XButtonCloseEnable } from '../Manage/ManageStyle';
import {
	Radio,
	Input,
	Button,
	Row,
	Col,
	InputNumber,
	Card,
	Result,
	DatePicker,
	Space,
} from 'antd';
import { XRadio } from '../CaseForm/CaseFormStyle';

import { useNavigate } from 'react-router-dom';
import { getCasesApi, getCasesReset } from '../../actions/getCasesAction';
import {
	caseStatuses,
	dateFilters,
	medicalCoverage,
} from '../../utils/constants';
import {
	checkModulePermissions,
	toTitleCase,
	truncateString,
	urlPagination,
} from '../../utils/helper';
import {
	getStateDropdownApi,
	getStateDropdownReset,
} from '../../actions/getStateDropdownAction';
import {
	getHospitalDropdownApi,
	getHospitalDropdownReset,
} from '../../actions/getHospitalDropdownAction';
import { editCaseApi, editCaseReset } from '../../actions/editCaseAction';
import DataTable from '../../components/DataTable/DataTable';
import CaseSearch from '../../components/CaseSearch/CaseSearch';
import { filterByLabel, handleInputChange } from '../../utils/formFunctions';
import Header from '../../components/Header/Header';
import {
	getInsuranceDropdownApi,
	getInsuranceDropdownReset,
} from '../../actions/getInsuranceDropdownAction';
import { getOrganizationsApi } from '../../actions/getOrganizationsAction';
import { getRegionsApi } from '../../actions/getRegionsAction';
import {
	SearchButton,
	XInputText,
} from '../AnalyseCoverage/AnalyseCoverageStyle';
const { TextArea } = Input;

const CaseClosure = (props) => {
	const {
		getCases,
		getCasesState,
		editCase,
		editCaseReset,
		editCaseState,
		getStateDropdownState,
		getStateDropdown,
		getHospitalDropdown,
		getHospitalDropdownState,
		getInsuranceDropdown,
		getRegions,
		getRegionsState,
		getOrganizations,
		getOrganizationsState,
	} = props;

	const navigate = useNavigate();
	const [tableData, setTableData] = useState([]);
	const [totalRecords, setTotalRecords] = useState('');
	const defaultFilters = {
		search: '',
		page: 1,
		sort: '',
		sortDirection: 'descend',
		created_on: 'all',
		hospital: '',
		state: '',
		status: '',
		insurance_company: '',
		medical_coverage: '',
		limit: 10,
	};
	const [form] = XForm.useForm();
	const [form2] = XForm.useForm();

	const [modalOpen, setModalOpen] = useState([false, '']);
	const [formData, setFormData] = useState({
		status: '',
	});
	const [open, setOpen] = useState(false);
	const [displayClaimAmount, setDisplayClaimAmount] = useState(false);
	const [insuranceCompanies, setInsuranceCompanies] = useState([]);
	const getFiltersFromUrl = () => {
		const urlParams = new URLSearchParams(window.location.search);
		const urlFilters = {};

		// Iterate through each query parameter and set it in the state
		for (const [key, value] of urlParams.entries()) {
			urlFilters[key] = value;
		}

		// Merge with default filters or initial state as needed
		const filters = {
			...defaultFilters, // Replace with your default filters
			...urlFilters,
		};

		return filters;
	};
	const initialFilters = getFiltersFromUrl();

	const [filters, setFilters] = useState(initialFilters);

	const showDrawer = () => {
		setOpen(true);
	};
	const onClose = () => {
		setOpen(false);
	};

	const onChange = (key) => {
		setFilters({
			...filters,
			[key.target.name]: key.target.value,
		});
	};

	const applyFilters = () => {
		const queryParams = new URLSearchParams(window.location.search);

		// Iterate through filters and update the query parameters
		Object.entries(filters).forEach(([key, value]) => {
			const currentValue = queryParams.get(key);

			// Check if the parameter exists in the current query and has a value, if yes, update its value
			if (currentValue !== null) {
				queryParams.set(key, value);
			} else if (queryParams.has(key)) {
				// If the parameter exists but has no value, keep its existing value
				queryParams.set(key, queryParams.get(key));
			} else {
				// If the parameter does not exist in the current query, append it with the current value
				queryParams.append(key, value);
			}
		});

		// Set the page to 1
		queryParams.set('page', '1');
		queryParams.set('sort', 'createdAt');
		queryParams.set('sortDirection', 'descend');
		filters.page = 1;

		// Update the URL
		const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
		window.history.pushState({}, '', newUrl);
	};

	useEffect(() => {
		// Retrieve filters from the updated URL
		const updatedFilters = getFiltersFromUrl();
		setFilters({ ...updatedFilters });

		// Make API call with updated filters and pagination
		checkModulePermissions('case-closure').authorized &&
			getCases({ ...updatedFilters });
	}, [window.location.search]);

	const clearFilters = () => {
		const queryParams = new URLSearchParams(window.location.search);

		// Iterate through defaultFilters and update the query parameters
		Object.entries(defaultFilters).forEach(([key, value]) => {
			const currentValue = queryParams.get(key);

			// Check if the parameter exists in the current query and has a value, if yes, update its value
			if (currentValue !== null) {
				queryParams.set(key, value);
			} else if (queryParams.has(key)) {
				// If the parameter exists but has no value, keep its existing value
				queryParams.set(key, queryParams.get(key));
			} else {
				// If the parameter does not exist in the current query, append it with the default value
				queryParams.append(key, value);
			}
		});

		// Clear the form fields
		form2.resetFields();

		// Update the state with default values
		setFilters(defaultFilters);

		// Update the URL
		const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
		window.history.pushState({}, '', newUrl);
	};

	const columns = [
		{
			title: 'Case ID',
			dataIndex: 'case_id',
			fixed: 'left',
			width: 150,
			className: 'custom-border-left',
		},
		{
			title: 'Patient ID',
			dataIndex: 'mrn',
			fixed: 'left',
			width: 150,
			render: (mrn) => (
				<div>
					<div>{mrn}</div>
				</div>
			),
		},
		{
			title: 'Case Name',
			dataIndex: 'patient_name',
			fixed: 'left',
			width: 150,

			render: (patient_name) => (
				<div>
					<div
						style={{
							whiteSpace: 'pre-wrap',
							wordWrap: 'break-word',
						}}
					>
						{truncateString(patient_name, 25)}
					</div>
				</div>
			),
		},
		{
			title: 'Insurance Company',
			dataIndex: 'insurance_company',
			width: 300,

			render: (insurance_company) => (
				<div>
					<div
						style={{
							whiteSpace: 'pre-wrap',
							wordWrap: 'break-word',
						}}
					>
						{insurance_company}
					</div>
				</div>
			),
		},
		{
			title: 'Hospital',
			dataIndex: 'hospital',
			width: 200,

			render: (hospital) => (
				<div>
					<div
						style={{
							whiteSpace: 'pre-wrap',
							wordWrap: 'break-word',
						}}
					>
						{hospital}
					</div>
				</div>
			),
		},
		{
			title: 'State',
			dataIndex: 'state',
			width: 150,

			render: (state) => (
				<div>
					<div
						style={{
							whiteSpace: 'pre-wrap',
							wordWrap: 'break-word',
						}}
					>
						{state}
					</div>
				</div>
			),
		},
		{
			title: 'Medicare Coverage',
			dataIndex: 'medical_coverage',
			width: 150,

			render: (medical_coverage) => (
				<div>
					<div
						style={{
							whiteSpace: 'pre-wrap',
							wordWrap: 'break-word',
						}}
					>
						{medical_coverage}
					</div>
				</div>
			),
		},
		{
			title: 'Allocated to',
			dataIndex: 'allocated',
			width: 150,

			render: (allocated) => (
				<div>
					<div
						style={{
							whiteSpace: 'pre-wrap',
							wordWrap: 'break-word',
						}}
					>
						{allocated}
					</div>
				</div>
			),
		},
		{
			title: 'Status',
			dataIndex: 'status',
			width: 150,

			render: (status) => (
				<div>
					<div
						style={{
							whiteSpace: 'pre-wrap',
							wordWrap: 'break-word',
						}}
					>
						{status}
					</div>
				</div>
			),
		},
		{
			title: 'Created On',
			dataIndex: 'createdAt',
			width: 150,

			sorter: (a, b) => a.createdAt - b.createdAt,
			sortDirections: ['ascend', 'descend'],
			render: (createdAt) => (
				<div>
					<div
						style={{
							whiteSpace: 'pre-wrap',
							wordWrap: 'break-word',
						}}
					>
						{createdAt}
					</div>
				</div>
			),
		},
		{
			title: <div className="text-center">Action</div>,
			dataIndex: 'action',
			fixed: 'right',
			width: 150,

			render: (action) => (
				<div>
					<div>{action}</div>
				</div>
			),
		},
	];

	console.log(formData, '-----------------');
	const handleDateChange = (date, name) => {
		const formattedDate = date
			? date.format('YYYY-MM-DDTHH:mm:ss.SSSZ')
			: null;
		setFormData((prevState) => ({
			...prevState,
			[name]: formattedDate,
		}));
	};

	// use effects
	useEffect(() => {
		if (getCasesState.apiState === 'success') {
			if (getCasesState.data.all_cases.length > 0) {
				const updatedData = [];
				const updatedSearchOptions = [];
				setTotalRecords(getCasesState.data.total_count);
				getCasesState.data.all_cases.map((item, index) => {
					updatedSearchOptions.push({ value: item.patient_name });
					updatedData.push({
						key: item._id,
						case_id: (
							<div
								className={`claim_category_line ${
									item.status === 'rejected'
										? 'rejected'
										: !item.claim_category
										? 'neutral'
										: item.claim_category
								}`}
							>
								{item.case_id}
							</div>
						),

						mrn: item.mrn,
						patient_name: item.patient_name,
						insurance_company: item.insurance
							? item.insurance.name
							: '-',
						allocated: item.allocated_to ? item.allocated_to : '-',
						hospital: item.hospitals ? item.hospitals.name : '-',
						medical_coverage: item.coverage_feedbacks
							? item.coverage_feedbacks
									?.updated_medical_coverage_applicable ?? '-'
							: '-',
						state: item.state ? item.state : '-',
						status: (
							<>
								<XTag className={item.status}>
									{toTitleCase(item.status)}
								</XTag>
							</>
						),
						createdAt: (
							<div className="showDate">
								{moment(item.createdAt).format('DD-MM-YYYY')}
							</div>
						),
						action: (
							<div className="text-center">
								{![
									'successful',
									'rejected',
									'pending',
									'allocated',
								].includes(item.status) ? (
									<XButtonCloseEnable
										style={{
											width: '100px',
											color: '#007299',
										}}
										disabled={item.status === 'pending'}
										onClick={() => {
											setModalOpen([
												true,
												{
													patient_name:
														item.patient_name,
												},
											]);
											setFormData({
												...formData,
												id: item._id,
											});
										}}
										type="text"
									>
										Close Case
									</XButtonCloseEnable>
								) : (
									<XButtonClose
										disabled
										style={{
											width: '100px',
											border: '1px solid',
										}}
									>
										{item.status === 'pending' ||
										item.status === 'allocated'
											? 'Close Case'
											: 'Closed'}
									</XButtonClose>
								)}
							</div>
						),
						statusMobile: item.status,
					});
				});
				setTableData(updatedData);
			} else {
				setTableData([]);
				setTotalRecords(0);
			}
		}
	}, [getCasesState]);

	useEffect(() => {
		if (editCaseState.apiState === 'success') {
			editCaseReset();
			setModalOpen([false, '']);
			// getCases({})
			const updatedFilters = getFiltersFromUrl();
			getCases({ ...updatedFilters });
		}
		form.resetFields();
		form.setFieldsValue({});
		// else if (editCaseState.apiState === 'error') {
		// 	notification.error({ message: editCaseState.message });
		// }
	}, [editCaseState]);

	useEffect(() => {
		if (formData.status === 'rejected')
			setFormData((prev) => ({
				...prev,
				claim_amount: null,
				claim_category: null,
			}));
	}, [formData.status]);

	return (
		<>
			<>
				<Col lg={24} xl={24} xs={0} md={0}>
					<Header headerTag="Case Closure" />
				</Col>
				<Col lg={0} xl={0} xs={24} md={24}>
					<HeaderFile activeNav="caseClosure" />
				</Col>
			</>
			<XContainer fluid>
				{checkModulePermissions('case-closure').authorized ? (
					<Card
						bodyStyle={{ padding: '10px' }}
						style={{ borderRadius: '30px' }}
					>
						<CaseSearch
							filters={[
								<XForm.Item
									name="status"
									colon={false}
									style={{
										display: 'flex',
										flexDirection: 'column',
									}}
									label="Status"
									className="removeFormMargin"
								>
									<XRadio.Group
										onChange={onChange}
										name="status"
									>
										<Row>
											{caseStatuses.map((item, index) => (
												<Col
													xs={12}
													lg={12}
													md={12}
													xl={12}
												>
													<XRadio
														value={item.value}
														className="filter-sub-item"
													>
														{item.label}
													</XRadio>
												</Col>
											))}
										</Row>
									</XRadio.Group>
								</XForm.Item>,
								<XForm.Item
									name="hospital"
									label="Hospital"
									style={{ fontWeight: 'bold' }}
									colon={false}
									className="removeFormMargin"
								>
									<XSelect
										size="large"
										placeholder="Select Hospital"
										name="hospital"
										allowClear
										className="filter-sub-item"
										showSearch={true}
										defaultValue={
											filters.hospital
												? filters.hospital
												: null
										}
										onFocus={() => {
											getHospitalDropdown();
										}}
										onSearch={(search) => {
											if (search.length >= 3) {
												getHospitalDropdown({
													search,
												});
											} else if (search.length === 0) {
												getHospitalDropdown();
											}
										}}
										onClear={() => {
											setFilters({
												...filters,
												hospital: '',
											});
										}}
										filterOption={filterByLabel}
										options={
											getHospitalDropdownState.apiState ===
												'success' &&
											getHospitalDropdownState.data
												.hospitals?.length > 0 &&
											getHospitalDropdownState.data.hospitals.map(
												(itm) => {
													return {
														label: itm.name,
														value: itm._id,
													};
												},
											)
										}
										onSelect={(value) => {
											setFilters({
												...filters,
												hospital: value,
											});
										}}
									/>
								</XForm.Item>,
								<XForm.Item
									name="organization"
									label={'Organization'}
								>
									<XSelect
										size="large"
										placeholder="Select Organization"
										name="organization"
										showSearch={true}
										className="removeBg"
										onFocus={() => {
											getOrganizations();
										}}
										onSearch={(search) => {
											if (search.length >= 3) {
												getOrganizations({
													search,
												});
											} else if (search.length === 0) {
												getOrganizations();
											}
										}}
										// filterOption={filterByLabel}
										options={
											getOrganizationsState.apiState ===
												'success' &&
											getOrganizationsState.data.list
												?.length > 0 &&
											getOrganizationsState.data.list.map(
												(itm) => {
													return {
														label: itm.name,
														value: itm._id,
													};
												},
											)
										}
										onSelect={(value, options) => {
											setFilters({
												...filters,
												organization: value,
											});
										}}
									></XSelect>
								</XForm.Item>,
								<XForm.Item name="region" label={'Region'}>
									<XSelect
										size="large"
										placeholder="Select Region"
										name="region"
										showSearch={true}
										className="removeBg"
										onFocus={() => {
											getRegions();
										}}
										onSearch={(search) => {
											if (search.length >= 3) {
												getRegions({
													search,
												});
											} else if (search.length === 0) {
												getRegions();
											}
										}}
										filterOption={filterByLabel}
										options={
											getRegionsState.apiState ===
												'success' &&
											getRegionsState.data.regions
												?.length > 0 &&
											getRegionsState.data.regions.map(
												(itm) => {
													return {
														label: itm.name,
														value: itm._id,
													};
												},
											)
										}
										onSelect={(value, options) => {
											setFilters({
												...filters,
												region: value,
											});
										}}
									></XSelect>
								</XForm.Item>,
								<XForm.Item
									name="state"
									colon={false}
									label="State"
									style={{
										display: 'flex',
										flexDirection: 'column',
									}}
									className="removeFormMargin"
								>
									<XSelect
										size="large"
										allowClear
										className="filter-sub-item"
										placeholder="Select State"
										defaultValue={
											filters.state ? filters.state : null
										}
										onFocus={() => {
											getStateDropdown();
										}}
										// onSearch={(search) => {
										// 	if (search.length >= 3) {
										// 		getStateDropdown({
										// 			search,
										// 		});
										// 	} else if (search.length === 0) {
										// 		getStateDropdown();
										// 	}
										// }}
										onClear={() => {
											setFilters({
												...filters,
												state: '',
												insurance_company: '',
											});
											form2.setFieldValue(
												'insurance_company',
												'',
											);
											setInsuranceCompanies([]);
										}}
										showSearch={true}
										filterOption={filterByLabel}
										options={
											getStateDropdownState.apiState ===
												'success' &&
											getStateDropdownState.data
												.all_states?.length > 0 &&
											getStateDropdownState.data.all_states.map(
												(itm) => {
													return {
														label: itm.state,
														value: itm.state,
														providers:
															itm.providers,
													};
												},
											)
										}
										onSelect={(value, options) => {
											setFilters({
												...filters,
												state: value,
											});
											setInsuranceCompanies(
												options.providers,
											);
										}}
									/>
								</XForm.Item>,
								<XForm.Item
									name="insurance_company"
									label="Insurance Company"
									colon={false}
									className="removeFormMargin"
								>
									<XSelect
										size="large"
										placeholder="Select Insurance Company"
										name="insurance_company"
										allowClear
										className="filter-sub-item"
										showSearch={true}
										defaultValue={
											filters.insurance_company
												? filters.insurance_company
												: null
										}
										onFocus={() => {
											getInsuranceDropdown();
										}}
										onSearch={(search) => {
											if (search.length >= 3) {
												getInsuranceDropdown({
													search,
												});
											} else if (search.length === 0) {
												getInsuranceDropdown();
											}
										}}
										onClear={() => {
											setFilters({
												...filters,
												insurance_company: '',
											});
										}}
										filterOption={filterByLabel}
										options={
											insuranceCompanies.length > 0 &&
											insuranceCompanies.map((itm) => {
												return {
													label: itm.name,
													value: itm._id,
												};
											})
										}
										onSelect={(value) => {
											setFilters({
												...filters,
												insurance_company: value,
											});
										}}
									/>
								</XForm.Item>,
								<XForm.Item
									name="medical_coverage"
									colon={false}
									style={{
										display: 'flex',
										flexDirection: 'column',
									}}
									label="Medicare Coverage"
									className="removeFormMargin"
								>
									<XRadio.Group
										onChange={onChange}
										name="medical_coverage"
										style={{
											width: '100%',
										}}
										defaultValue={
											filters.patient_name
												? filters.patient_name
												: null
										}
									>
										<Row>
											{medicalCoverage.map(
												(item, index) => (
													<Col
														xs={12}
														lg={12}
														md={12}
														xl={12}
													>
														<XRadio
															value={item.value}
															className="filter-sub-item"
														>
															{item.label}
														</XRadio>
													</Col>
												),
											)}
										</Row>
									</XRadio.Group>
								</XForm.Item>,
								<XForm.Item
									name="created_on"
									colon={false}
									label="Date Within"
									className="removeFormMargin"
								>
									<XRadio.Group
										onChange={onChange}
										name="created_on"
										defaultValue={
											filters.created_on
												? filters.created_on
												: null
										}
									>
										<Row>
											{dateFilters.map((item, index) => (
												<Col
													xs={12}
													lg={12}
													md={12}
													xl={12}
												>
													<XRadio
														value={item.value}
														className="filter-sub-item"
													>
														{item.label}
													</XRadio>
												</Col>
											))}
										</Row>
									</XRadio.Group>
								</XForm.Item>,
							]}
							onClose={onClose}
							open={open}
							applyFilters={applyFilters}
							form={form2}
							clearFilters={clearFilters}
						/>
						<Row gutter={(15, 30)} style={{ padding: '20px ' }}>
							<Col
								style={{
									display: 'flex',
									alignItems: 'center',
								}}
								xs={24}
								md={10}
								lg={3}
								xl={3}
								xxl={3}
								className="mb-lg-2"
							>
								<p className="tableHeader">
									Total {totalRecords} Cases
								</p>{' '}
							</Col>
							<Col
								xs={24}
								md={14}
								lg={{ span: 6 }}
								xl={{ span: 6 }}
								xxl={{ span: 6 }}
								className="mb-4 mb-lg-0"
							>
								<Space>
									<XInputText
										allowClear
										style={{ borderRadius: '25px' }}
										// size="large"
										placeholder="Search for Cases"
										defaultValue={
											filters.patient_name
												? filters.patient_name
												: null
										}
										onChange={(v) => {
											if (v.target.value === '') {
												urlPagination(
													filters,
													'patient_name',
													'',
												);
												setFilters({
													...filters,
													patient_name: '',
												});
											} else {
												setFilters({
													...filters,
													patient_name:
														v.target.value,
												});
											}
										}}
										onKeyDown={(e) => {
											if (e.key === 'Enter') {
												const searchValue =
													filters.patient_name
														? filters.patient_name
														: '';
												if (searchValue) {
													urlPagination(
														filters,
														'patient_name',
														searchValue,
													);
													setFilters({
														...filters,
														patient_name:
															searchValue,
													});
												}
											}
										}}
									/>
									<SearchButton
										style={{
											borderRadius: '20px',
											color: '#007299',
										}}
										onClick={() => {
											// Trigger search when the button is clicked
											const searchValue =
												filters.patient_name
													? filters.patient_name
													: '';
											if (searchValue) {
												urlPagination(
													filters,
													'patient_name',
													searchValue,
												);
												setFilters({
													...filters,
													patient_name: searchValue,
												});
											}
										}}
										className="px-4"
										size="large"
									>
										Search
									</SearchButton>
								</Space>
								{/* <XForm.Item>
									<XSearch
										placeholder="Search for Cases"
										allowClear
										enterButton="Search"
										size="large"
										defaultValue={
											filters.patient_name
												? filters.patient_name
												: null
										}
										onChange={(v) => {
											if (v.target.value === '') {
												urlPagination(
													filters,
													'patient_name',
													'',
												);
												setFilters({
													...filters,
													patient_name: '',
												});
											}
										}}
										onSearch={(v) => {
											urlPagination(
												filters,
												'patient_name',
												v,
											);
											setFilters({
												...filters,
												patient_name: v,
											});
										}}
									/>
								</XForm.Item> */}
							</Col>
							<Col
								xs={24}
								md={14}
								lg={{ span: 4, offset: 11 }}
								xl={{ span: 4, offset: 11 }}
								xxl={{ span: 4, offset: 11 }}
							>
								<XForm.Item className="text-right">
									<FilterButton
										onClick={() => showDrawer()}
										icon={
											<img
												src="/images/filter-add.svg"
												alt="bell"
												height={'15px'}
											/>
										}
									>
										Filters
									</FilterButton>
								</XForm.Item>
							</Col>
						</Row>
						<DataTable
							tableData={tableData}
							columns={columns}
							totalRecords={totalRecords}
							setFilters={setFilters}
							filters={filters}
							state={getCasesState.apiState}
							page={'case'}
						/>
					</Card>
				) : (
					<Result
						status="403"
						title="403"
						subTitle="Sorry, you are not authorized to access this page."
						extra={
							<Button
								type="primary"
								onClick={() => navigate('/')}
							>
								Back Home
							</Button>
						}
					/>
				)}
			</XContainer>
			<XModal
				title={`Close Case`}
				centered
				open={modalOpen[0]}
				onCancel={() => {
					setModalOpen([false, '']);
					setDisplayClaimAmount(false);
					setFormData({});
					form.resetFields('');
				}}
				className="modalButton"
				okText="Submit"
				footer={null}
				zIndex="9999"
			>
				<XForm
					// initialValues={formData}
					form={form}
					name="caseCloseForm"
					layout="vertical"
					autoComplete="off"
					onFinish={() => {
						editCase(formData);
					}}
				>
					<Row gutter={(15, 0)} className="mt-4">
						<Col xs={24}>
							<XForm.Item
								name="status"
								label="Case Status"
								rules={[
									{
										required: true,
										message: 'Please select case status!',
									},
								]}
							>
								<XRadio.Group
									onChange={(e) => {
										handleInputChange(
											e,
											formData,
											setFormData,
										);
										setFormData((prevData) => ({
											...prevData,
											status: e.target.value,
											is_claim_amount_settled: null,
											reimbursed_at: null,
											claim_category: null,
											claim_amount: null,
											remarks: '',
										}));
										if (e.target.value === 'successful') {
											setDisplayClaimAmount(true);
										} else {
											setDisplayClaimAmount(false);
										}
										form.setFieldsValue({
											is_claim_amount_settled: null,
											reimbursed_at: null,
											claim_category: null,
											claim_amount: null,
											remarks: '',
										});
									}}
									name="status"
								>
									<XRadio value={'successful'}>
										Claim Successful
									</XRadio>
									<XRadio value={'rejected'}>
										Claim Rejected
									</XRadio>
								</XRadio.Group>
							</XForm.Item>
						</Col>
						{!displayClaimAmount ? (
							<></>
						) : (
							<Col xs={24}>
								<Card>
									<Row>
										<Col xs={24}>
											<XForm.Item
												label="Payment Received"
												name="is_claim_amount_settled"
												className="mb-3"
												rules={[
													{
														required: true,
														message:
															'Please select!',
													},
												]}
											>
												<XRadio.Group
													onChange={(e) => {
														setFormData((prev) => ({
															...prev,
															is_claim_amount_settled:
																e.target.value,
															reimbursed_at: e
																.target.value
																? prev.reimbursed_at
																: null,
														}));
														if (!e.target.value) {
															form.resetFields([
																'reimbursed_at',
															]);
														}
													}}
													name="is_claim_amount_settled"
												>
													<XRadio value={true}>
														Yes
													</XRadio>
													<XRadio value={false}>
														No
													</XRadio>
												</XRadio.Group>
											</XForm.Item>
										</Col>
										{formData.is_claim_amount_settled ===
											true && (
											<>
												<Col xs={24}>
													<XForm.Item
														label="Payment Date"
														name="reimbursed_at"
														rules={[
															{
																required: true,
																message:
																	'Please select Payment Date!',
															},
														]}
													>
														<DatePicker
															name="reimbursed_at"
															size="large"
															className="w-100"
															placeholder="MM/DD/YYYY" // Add placeholder
															format="MM/DD/YYYY" // Set display format
															onChange={(date) =>
																handleDateChange(
																	date,
																	'reimbursed_at',
																)
															}
														/>
													</XForm.Item>
												</Col>
											</>
										)}
										<Col xs={24}>
											<XForm.Item
												label="Payment Type"
												name="claim_category"
												rules={[
													{
														required: true,
														message:
															'Please select Payment Type!',
													},
												]}
											>
												<XRadio.Group
													onChange={(e) => {
														handleInputChange(
															e,
															formData,
															setFormData,
														);
													}}
													name="claim_category"
												>
													<XRadio value={'full'}>
														Full
													</XRadio>
													<XRadio value={'partial'}>
														Partial
													</XRadio>
												</XRadio.Group>
											</XForm.Item>
										</Col>
									</Row>
									<Row gutter={[10]}>
										<Col xs={24}>
											<XForm.Item
												name="claim_amount"
												label="Claim Amount"
												rules={[
													{
														required: true,
														message:
															'Please select Claim Amount!',
													},
												]}
											>
												<InputNumber
													addonBefore={'$'}
													size="large"
													placeholder="Enter Claim Amount"
													name="claim_amount"
													min={0}
													style={{ width: '100%' }}
													onChange={(e) =>
														setFormData((prev) => ({
															...prev,
															claim_amount: e,
														}))
													}
												/>
											</XForm.Item>
										</Col>
									</Row>
								</Card>
							</Col>
						)}
					</Row>
					<Row className="mt-4">
						<Col xs={24} className="text-end">
							<XForm.Item name="remarks" label="Enter Remarks">
								<TextArea
									name="remarks"
									showCount
									maxLength={100}
									style={{
										height: 120,
										marginBottom: 24,
									}}
									onChange={(e) =>
										handleInputChange(
											e,
											formData,
											setFormData,
										)
									}
									placeholder="Enter remarks"
								/>
							</XForm.Item>
						</Col>
					</Row>
					<Row className="mt-4">
						<Col xs={24} className="text-end">
							<Button
								style={{
									color: '#fff',
									background: '#007299',
								}}
								htmlType="submit"
								className="modalButton"
							>
								Close Case
							</Button>
						</Col>
					</Row>
				</XForm>
			</XModal>
		</>
	);
};

const mapStateToProps = (state) => ({
	getCasesState: state.getCases,
	editCaseState: state.editCase,
	getStateDropdownState: state.getStateDropdown,
	getHospitalDropdownState: state.getHospitalDropdown,
	getInsuranceDropdownState: state.getInsuranceDropdown,
	getRegionsState: state.getRegions,
	getOrganizationsState: state.getOrganizations,
});

const mapDispatchToProps = (dispatch) => ({
	getCases: (data) => dispatch(getCasesApi(data)),
	getCasesReset: () => dispatch(getCasesReset()),
	editCase: (data) => dispatch(editCaseApi(data)),
	editCaseReset: () => dispatch(editCaseReset()),
	getStateDropdown: (params) => dispatch(getStateDropdownApi(params)),
	getStateDropdownReset: () => dispatch(getStateDropdownReset()),
	getHospitalDropdown: (params) => dispatch(getHospitalDropdownApi(params)),
	getHospitalDropdownReset: () => dispatch(getHospitalDropdownReset()),
	getInsuranceDropdown: (params) => dispatch(getInsuranceDropdownApi(params)),
	getInsuranceDropdownReset: () => dispatch(getInsuranceDropdownReset()),
	getRegions: (params) => dispatch(getRegionsApi(params)),
	getOrganizations: (data) => dispatch(getOrganizationsApi(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CaseClosure);
