import styled from 'styled-components';
import '../../styles/GlobalStyle';

export const CenterWrapper = styled.section`
	width: 100%;
	display: block;
	align-items: center;
	@media only screen and (max-width: 992.99px) {
		display: inline-table;
		align-items: center;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		margin: auto;
		width: 90%;
	}
	.loginInputsLbl label {
		font-family: ${({ theme }) => theme.colors.fontInter};
		font-style: normal;
		font-weight: 500;
		font-size: 16px !important;
		line-height: 22px;
		color: #002141 !important;
	}
	.loginInputsLbl .ant-form-item-control-input-content {
		background: #fff !important;
		border-radius: 10px;
	}
	.loginInputsLbl svg {
		color: #000;
	}

	@media only screen and (min-width: 2000.99px) {
		display: block !important;
	}
`;
export const XPara1 = styled.div`
	color: ${({ theme }) => theme.colors.menu2};
	color: rgba(0, 33, 65, 0.7);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	@media only screen and (max-width: 769px) {
		font-size: 14px;
	}
`;
