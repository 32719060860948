import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col, notification } from 'antd';
import { Logo, Title, XForm, XLoginBTN, XLottie } from '../Login/LoginStyle';
import { CenterWrapper } from '../ForgotEmailPassword/ForgotEmailPasswordStyle';
import { XPinInput, XPara1 } from './VerifyOtpStyle';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import LoginAnimation from '../../json/Login.json';
import Navbar from 'react-bootstrap/Navbar';
import { XContainer } from '../../styles/GlobalStyle';
// Actions
import { verifyOtpApi, verifyOtpReset } from '../../actions/verifyOtpAction';
import {
	sendPasswordResetEmailApi,
	sendPasswordResetEmailReset,
} from '../../actions/sendPasswordResetEmailAction';

const VerifyOtp = (props) => {
	const {
		verifyOtp,
		verifyOtpReset,
		verifyOtpState,
		sendPasswordResetEmail,
		sendPasswordResetEmailState,
		sendPasswordResetEmailReset,
	} = props;
	const [form] = XForm.useForm();
	const [formData, setFormData] = useState({
		email: localStorage.getItem('email'),
	});
	const navigate = useNavigate();

	useEffect(() => {
		if (sendPasswordResetEmailState.apiState === 'success') {
			notification.success({
				message: sendPasswordResetEmailState.message,
			});
			sendPasswordResetEmailReset();
		} else if (sendPasswordResetEmailState.apiState === 'error') {
			notification.error({
				message: sendPasswordResetEmailState.message,
			});
			sendPasswordResetEmailReset();
		}
	}, [sendPasswordResetEmailState]);

	useEffect(() => {
		if (verifyOtpState.apiState === 'success') {
			localStorage.setItem('otpVerified', true);
			verifyOtpReset();
			navigate('/reset-password');
		} else if (verifyOtpState.apiState === 'error') {
			notification.error({
				message: verifyOtpState.message,
			});
			verifyOtpReset();
		}
	}, [verifyOtpState]);
	useEffect(() => {
		// Add the class to the body tag
		document.body.classList.add('reclaimBody');

		// Clean up to remove the class when the component unmounts
		return () => {
			document.body.classList.remove('reclaimBody');
		};
	}, []);
	return (
		<XContainer fluid>
			<Row className="showDesktop">
				<Col xs={24}>
					<Navbar.Brand href="/">
						<Logo>
							<img
								className="img-fluid"
								src="./images/logo.svg"
								alt="logo"
							/>
						</Logo>
					</Navbar.Brand>
				</Col>
			</Row>
			<CenterWrapper>
				<Row className="showMobile mb-5">
					<Col xs={24}>
						<Logo>
							<img
								className="img-fluid"
								src="./images/reclaim-login-logo.svg"
								alt="logo"
							/>
						</Logo>
					</Col>
				</Row>
				<Row className="align-items-center">
					<Col
						xs={{ span: 24, order: 2 }}
						sm={{ span: 24, order: 2 }}
						lg={{ span: 8, order: 1 }}
					>
						<Title className="mb-2">OTP Verification</Title>
						<XPara1 className="mb-4">
							We sent a verification code to
							<span> {formData.email}</span>
						</XPara1>
						<XForm
							form={form}
							name="loginForm"
							layout="vertical"
							autoComplete="off"
							onFinish={() => verifyOtp(formData)}
						>
							<XForm.Item
								name="otp"
								className="pinInputOTP"
								rules={[
									{
										required: true,
										message: 'Please enter OTP',
									},
								]}
							>
								<XPinInput
									focus="true"
									length={6}
									initialvalue=""
									onChange={(value, index) => {
										setFormData({
											...formData,
											otp: value,
										});
									}}
									type="numeric"
									inputMode="number"
									style={{ padding: '0px' }}
									inputStyle={{
										borderColor: '#D8D8D7',
										background: '#F7F9FD',
									}}
									inputFocusStyle={{
										borderColor: '#D8D8D7',
										background: '#F7F9FD',
									}}
									onComplete={(value, index) => {}}
									autoSelect={true}
									regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
								/>
							</XForm.Item>

							<XForm.Item className="mt-2">
								<XPara1 className="mb-1">00:20</XPara1>
								<XPara1 className="mb-0">
									Don't Receive the OTP?{' '}
									<span
										onClick={() => {
											sendPasswordResetEmail({
												email: localStorage.getItem(
													'email',
												),
											});
										}}
									>
										RESEND
									</span>
								</XPara1>
							</XForm.Item>

							<XForm.Item className="mt-4">
								<XLoginBTN htmlType="submit">Submit</XLoginBTN>
							</XForm.Item>
						</XForm>
					</Col>
					<Col
						xs={{ span: 24, order: 1 }}
						sm={{ span: 24, order: 1 }}
						lg={{ span: 10, offset: 6, order: 2 }}
						className="text-center showDesktop"
					>
						<XLottie animationData={LoginAnimation} loop={true} />
					</Col>
				</Row>
			</CenterWrapper>
		</XContainer>
	);
};

const mapStateToProps = (state) => ({
	verifyOtpState: state.verifyOtp,
	sendPasswordResetEmailState: state.sendPasswordResetEmail,
});

const mapDispatchToProps = (dispatch) => ({
	verifyOtp: (params) => dispatch(verifyOtpApi(params)),
	verifyOtpReset: () => dispatch(verifyOtpReset()),
	sendPasswordResetEmail: (params) =>
		dispatch(sendPasswordResetEmailApi(params)),
	sendPasswordResetEmailReset: () => dispatch(sendPasswordResetEmailReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyOtp);
