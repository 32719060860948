import styled from 'styled-components';
import '../../styles/GlobalStyle';

import { Modal, Card } from 'antd';
import Lottie from 'lottie-react';

export const XLottie = styled(Lottie)`
	position: relative;
	background: transparent;
	width: 100%;
`;

export const XCard = styled(Card)`
	.ant-card-body {
		height: 410px;
	}
`;
export const XModalAnimation = styled(Modal)`
	.ant-modal-close {
		top: -5px !important;
		inset-inline-end: 0px !important;
		display: none;
	}
	.ant-card {
		border: none;
		background: transparent;
		box-shadow: none;
	}
	.ant-modal-footer {
		margin: 0px;
	}
	.ant-modal-content {
		background: transparent !important;
		box-shadow: none;
		padding: 80px 60px;
		@media only screen and (max-width: 511.99px) {
			padding: 15px !important;
		}
		&:after {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			top: 45px;
			content: '';
			display: block;
			width: 114px;
			height: 51px;
			background: url('/images/loader_pin.svg') no-repeat center top;

			@media only screen and (max-width: 511.99px) {
				top: -21px;
			}
		}
	}
	.ant-card-body {
		background: #fff;
		border-radius: 40px;
		border: 15px solid rgba(2, 117, 155, 0.1);
		ul {
			margin: 0px;
			padding: 0px;
			min-height: 285px;
		}
		ul li {
			display: list-item;
			list-style-type: none;
			font-weight: 500;
			font-size: 14px;
			line-height: 30px;
			margin-bottom: 30px;
			padding-left: 40px;
			align-items: center;
			display: flex;
			@media only screen and (max-width: 511.99px) {
				line-height: 16px;
			}
			&:last-child {
				margin-bottom: 0px;
			}
			span {
				position: absolute;
				left: 30px;
				width: 30px;
				height: 30px;
				border-radius: 30px;
				text-align: center;
				@media only screen and (max-width: 511.99px) {
					line-height: 30px;
				}
			}
		}
		ul li.active {
			color: #008542;
			transition: color 3s;
			span {
				background: #008542;
				transition: background-color 3s;
			}
		}
		ul li.inprocess {
			color: #e5aa11;
			transition: color 3s;
			span {
				background: #e5aa11;
				transition: background-color 3s;
			}
		}

		.laser {
			width: 115%;
			margin-left: -15%;
			background-color: rgba(2, 117, 155, 0.1);
			border-radius: 10px;
			height: 1px;
			position: absolute;
			height: 10px;
			top: 3%;
			z-index: 3;
			-webkit-animation: scanning 5s infinite;
			-moz-animation: scanning 5s infinite;
			animation: scanning 5s infinite;
		}

		@keyframes scanning {
			50% {
				-webkit-transform: translateY(370px);
				-moz-transform: translateY(370px);
				transform: translateY(370px);
			}
		}
	}
`;
export const XLottieLoader = styled.section`
	width: 100%;
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	right: 0;
	top: 20px;
	bottom: 0;
`;
