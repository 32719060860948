import styled from 'styled-components';
import '../../styles/GlobalStyle';
import { Form, Checkbox, Button } from 'antd';
import Lottie from 'lottie-react';

export const CenterWrapper = styled.section`
	display: flex;
	align-items: center;
	@media only screen and (max-width: 992.99px) {
		display: inline-table;
		align-items: center;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		margin: auto;
		width: 90%;
	}
	.loginInputsLbl label {
		font-family: ${({ theme }) => theme.colors.fontInter};
		font-style: normal;
		font-weight: 500;
		font-size: 16px !important;
		line-height: 22px;
		color: #002141 !important;
	}
	.loginInputsLbl .ant-form-item-control-input-content {
		background: #fff !important;
		border-radius: 10px;
	}
	.loginInputsLbl svg {
		color: #000;
	}
	.loginInputsLbl input {
		border-color: #dcdcdc;
	}
	@media only screen and (min-width: 2000.99px) {
		display: block !important;
	}
`;

export const Logo = styled.div`
	width: 100%;
	position: relative;
	padding: 20px 0px 30px 0px;
	@media only screen and (max-width: 767.99px) {
		padding: 20px 0px 0px 0px;
	}
`;
export const Title = styled.h1`
	margin: 0px;
	background: var(
		--new,
		linear-gradient(90deg, #69eacb 0%, #e2a3ff 48%, #6654f1 100%)
	);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-size: 36px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	@media only screen and (max-width: 992.99px) {
		font-size: 28px;
		font-size: 20px;
		margin-top: -20px;
		background: none;
		background-clip: unset;
		-webkit-background-clip: unset;
		-webkit-text-fill-color: unset;
		color: #0e4c88;
	}
`;
export const XForm = styled(Form)`
	label {
		color: #000 !important;
		font-size: 14px !important;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
	input {
		border-radius: 10px;
		border: 1px solid #d8d8d7;
		background: #f7f9fd;
		padding-top: 10px;
		padding-bottom: 10px;
		@media only screen and (max-width: 768.99px) {
			padding-top: 10px;
			padding-bottom: 10px;
		}
	}
	button {
		width: 100%;
		border-radius: 50px !important;
		background: #007299;
		color: #fff;
		font-size: 16px !important;
		padding: 10px 0px;
		height: auto;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		&:hover {
			background: #007299 !important;
			color: #fff !important;
		}
		@media only screen and (max-width: 768.99px) {
			font-size: 14px;
			padding: 10px 0px;
		}
	}
	.ant-input-password {
		border-radius: 10px;
		border: 1px solid #d8d8d7;
		background: #f7f9fd;
		padding-top: 10px;
		padding-bottom: 10px;
		@media only screen and (max-width: 768.99px) {
			padding-top: 10px;
			padding-bottom: 10px;
		}
	}
	.login-form-forgot {
		float: right;
		text-decoration: none;
		color: ${({ theme }) => theme.colors.menu2};
		text-align: right;
		font-family: Poppins;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		color: #007299;
		@media only screen and (max-width: 768.99px) {
			font-size: 14px;
			line-height: 22px;
			font-weight: 500;
			font-size: 16px;
			line-height: 22px;
			color: #0e4c88 !important;
		}
	}
`;
export const XCheckbox = styled(Checkbox)`
	color: #656565;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	@media only screen and (max-width: 992.99px) {
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		color: #002141;
		.ant-checkbox-inner {
			background-color: #b7d7e8 !important;
			border-color: #b7d7e8 !important;
		}
		.ant-checkbox-checked .ant-checkbox-inner {
			background-color: #0e4c88 !important;
			border-color: #0e4c88 !important;
		}
	}
`;
export const XLottie = styled(Lottie)`
	position: relative;
	background: transparent;
	@media only screen and (max-width: 768.99px) {
		width: 75%;
		position: relative;
		margin: 0px auto;
	}
`;
export const XMobileLogin = styled.section`
	display: none;
	@media only screen and (max-width: 992.99px) {
		width: 100%;
		position: relative;
		padding: 15px;
		display: flex;
		align-items: center;
		height: 100%;
	}
`;
export const XLoginBTN = styled(Button)`
	width: 100%;
	border-radius: 50px !important;
	background: #007299 !important;
	color: ${({ theme }) => theme.colors.white};
	font-family: ${({ theme }) => theme.colors.fontInter};
	padding: 10px 0px;
	height: auto;
	font-size: 20px !important;
	font-style: normal;
	font-weight: 700;
	line-height: 24px;
	letter-spacing: -0.28px;
	&:hover,
	&:active {
		background: #007299 !important;
		color: ${({ theme }) => theme.colors.white} !important;
	}
	@media only screen and (max-width: 992.99px) {
		padding: 15px 0px !important;
		background: #0e4c88 !important;
		&:hover,
		&:active {
			background: #0e4c88 !important;
			color: ${({ theme }) => theme.colors.white} !important;
		}
	}
`;
