import styled from 'styled-components';
import '../../styles/GlobalStyle';
import {
	Card,
	Button,
	Divider,
	List,
	Collapse,
	Dragger,
	Tabs,
	Radio,
} from 'antd';
export const XCardPreview = styled(Card)`
	background: ${({ theme }) => theme.colors.white};
	border: 1px solid rgba(87, 99, 111, 0.15);
	border-radius: 10px;
	.ant-card-body {
		border-radius: 10px;
		border: none;
		@media only screen and (max-width: 768.99px) {
			padding: 10px !important;
		}
	}
`;
export const XCardInner = styled(Card)`
	width: 100%;
	position: relative;
	font-family: ${({ theme }) => theme.colors.fontPoppins} !important;
	border-radius: 10px;
	.ant-card-body {
		padding: 0px;
		@media only screen and (max-width: 768.99px) {
		}
	}
	.headerTitles {
		font-weight: 500;
		font-size: 14px;
		line-height: 21px;
		color: ${({ theme }) => theme.colors.menu2};
		span {
			margin-left: 5px;
			color: ${({ theme }) => theme.colors.color12};
		}
	}
	.cardHeader {
		width: 100%;
		position: relative;
		background: ${({ theme }) => theme.colors.white};
		border-bottom: 1px solid rgba(87, 99, 111, 0.2);
		border-radius: 10px 10px 0px 0px;
		padding: 24px;
	}
	.hadPadding {
		width: 100%;
		position: relative;
		padding: 24px;
		border-radius: 0px;
		background: #f7f8fa;
	}
	p.para2 {
		margin-bottom: 15px;
		font-weight: 400;
		font-size: 14px;
		line-height: 22px;
		color: ${({ theme }) => theme.colors.menu2};
	}
	.listTitle {
		width: 100%;
		position: relative;
		font-weight: 500;
		font-size: 14px;
		line-height: 22px;
		color: ${({ theme }) => theme.colors.menu2};
	}
	ul.listItems {
		margin: 0px;
		padding: 0px;
	}
	ul.listItems li {
		display: list-item;
		margin-left: 15px;
		font-size: 14px;
		line-height: 22px;
		color: ${({ theme }) => theme.colors.menu2};
	}
`;
export const XTabs = styled(Tabs)`
	border-radius: 10px;
	.ant-tabs-nav-wrap {
		display: inline-block !important;
		border-radius: 0px !important;
	}
	.ant-tabs-tab {
		width: 50%;
		display: block;
		text-align: center !important;
		border-radius: 10px 10px 0px 0px !important;
		font-weight: 500 !important;
		font-size: 14px !important;
		&:hover {
			.ant-tabs-tab-btn {
				color: ${({ theme }) => theme.colors.menu2} !important;
			}
		}
	}
	.ant-tabs-tab-active {
		background: #007299 !important;
		&:hover {
			.ant-tabs-tab-btn {
				color: ${({ theme }) => theme.colors.white} !important;
			}
		}
	}
	.ant-tabs-tab-active .ant-tabs-tab-btn {
		color: ${({ theme }) => theme.colors.white} !important;
	}
	.ant-tabs-ink-bar .ant-tabs-ink-bar-animated {
		// width: 150.2px !important;
		// left: 227.3px !important;
	}
`;
export const XRadio = styled(Radio)`
	color: ${({ theme }) => theme.colors.menu2};
	font-weight: 400;
	font-size: 14px !important;
	line-height: 18px !important;
	.ant-form-item-control-input {
		min-height: auto !important;
	}
	.ant-radio-checked .ant-radio-inner {
		background: #007299;
		border-color: #007299;
	}
`;
