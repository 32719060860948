import styled, { css } from 'styled-components';
import '../../styles/GlobalStyle';
import Lottie from 'lottie-react';
import {
	Card,
	Button,
	Divider,
	Checkbox,
	Input,
	Collapse,
	Tag,
	Upload,
	Dropdown,
} from 'antd';

export const XCardsGrdBG = styled(Card)`
	background: var(
		--Linear,
		linear-gradient(
			90deg,
			rgba(105, 234, 203, 0.15) 0%,
			rgba(234, 204, 248, 0.15) 48%,
			rgba(102, 84, 241, 0.15) 100%
		)
	);
	.ant-card-body {
		padding: 15px;
		@media only screen and (max-width: 768.99px) {
		}
	}
`;
export const XLottieMargin = styled.section`
	margin: -60px 0px -60px 0px;

	@media (min-width: 768.99px) and (max-width: 1500px) {
		margin: -20px 0px -30px 0px !important;
	}

	@media only screen and (max-width: 767.99px) {
		margin: -40px 0px -30px 0px !important;
	}
`;
export const XLottieOverlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.8);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999;
`;
export const Title = styled.h1`
	margin-top: 15px;
	text-align: center;
	background: linear-gradient(90deg, #69eacb 0%, #e2a3ff 48%, #6654f1 100%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-size: 50px;
	font-style: normal;
	font-weight: 600;
	line-height: 60px;
	letter-spacing: 1.25px;
	text-transform: capitalize;
	@media only screen and (max-width: 767.99px) {
		margin: 0px;
	}
`;
export const XPara1 = styled.div`
	color: ${({ theme }) => theme.colors.menu2};
	font-family: ${({ theme }) => theme.colors.fontInter};
	font-size: 16px;
	font-style: normal;
	padding: 0px;
	font-weight: 500;
	line-height: 32px; /* 175% */
	padding: 15px;
	@media only screen and (max-width: 769px) {
		font-size: 14px;
		line-height: 24px;
		padding-left: 0px;
	}
	.readMore button {
		display: block !important;
		color: #007299 !important;
		text-decoration: none !important;
		margin-left: 0px !important;
		padding-left: 0px !important;
	}
`;
export const XButtonAdd = styled(Button)`
	${
		'' /* background: ${({ theme }) => theme.colors.color12} !important;
	border-color: ${({ theme }) => theme.colors.color12} !important; */
	}

	background: rgba(223, 235, 240, 0.5);
	border: 1px solid rgba(0, 114, 153, 0.5);
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
	border-radius: 5px;
	svg {
		margin-top: 3px;
		color: #007299;
	}
	&:hover {
		background: #007299 !important;
		svg {
			color: ${({ theme }) => theme.colors.white} !important;
		}
	}
	.customBntHover {
		width: auto !important;
		color: #fff !important;
	}
	@media only screen and (max-width: 767.99px) {
	}
`;
export const XTitleHead = styled(Divider)`
	margin: 0px;
	cursor: text !important;
	height: auto !important;
	font-family: ${({ theme }) => theme.colors.fontInter};
	font-size: 20px !important;
	font-style: normal;
	font-weight: 600 !important;
	line-height: 28px;
	padding-left: 5px;
	display: block !important;
	white-space: normal !important;
	@media only screen and (max-width: 768.99px) {
		font-size: 16px !important;
		line-height: 20px !important;
	}
	.ant-divider-inner-text {
		padding: 0px !important;
		text-align: left;
	}
	${
		'' /* .color1 {
		color: #69b4fd;
	}
	.color2 {
		color: #fda171;
	}
	.color3 {
		color: #7a82fd;
	}
	.color4 {
		color: #68ca84;
	}
	.color5 {
		color: #cd4949;
	}
	.color6 {
		color: #836217;
	}
	.color7 {
		color: #9b68bf;
	} */
	}
	${
		'' /* .color1 {
		color: #69b4fd;
	}
	.color2 {
		color: #fda171;
	}
	.color3 {
		color: #7a82fd;
	}
	.color4 {
		color: #68ca84;
	}
	.color5 {
		color: #cd4949;
	}
	.color6 {
		color: #836217;
	} */
	}
	.color1,
	.color2,
	.color3,
	.color4,
	.color5,
	.color6,
	.color7 {
		color: ${({ theme }) => theme.colors.menu2};
	}
`;
export const XDivider = styled(Divider)`
	border-color: rgba(0, 0, 0, 0.25);
	margin: 40px 0px 25px 0px;
	@media only screen and (max-width: 768.99px) {
	}
`;
export const XButtonLike = styled(Button)`
	background: ${({ theme }) => theme.colors.white};
	border: 1px solid ${({ theme }) => theme.colors.color6};
	color: ${({ theme }) => theme.colors.menu2};
	img,
	svg {
		width: 18px !important;
	}
	&:hover {
		background: ${({ theme }) => theme.colors.white} !important;
		border-color: ${({ theme }) => theme.colors.color6} !important;
		color: ${({ theme }) => theme.colors.menu2} !important;
	}
	.rotateButton {
		transform: rotate(3.142rad);
	}

	@media only screen and (max-width: 767.99px) {
	}
`;
export const XButtonConfirm = styled(Button)`
	background: #007299;
	border: 1px solid rgba(0, 114, 153, 0.1);
	color: #007299;
	font-family: ${({ theme }) => theme.colors.fontPoppins};
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.02em;
	padding: 0px;
	img,
	svg {
		width: 18px !important;
		filter: invert(31%) sepia(97%) saturate(658%) hue-rotate(156deg)
			brightness(94%) contrast(103%);
	}
	&[disabled] {
		background: rgba(0, 0, 0, 0.25) !important;
		border-color: rgba(0, 0, 0, 0.25) !important;
		color: rgba(0, 0, 0, 0.25);
		img {
			filter: invert(0);
		}
		&:hover {
			background: rgba(0, 0, 0, 0.25) !important;
			border-color: rgba(0, 0, 0, 0.25) !important;
			color: rgba(0, 0, 0, 0.25);
			img {
				filter: invert(0);
			}
		}
	}

	&:hover {
		background: #007299 !important;
		border-color: #007299 !important;
		color: ${({ theme }) => theme.colors.white} !important;
		img {
			filter: invert(1);
		}
	}

	.rotateButton {
		transform: rotate(3.142rad);
	}

	@media only screen and (max-width: 767.99px) {
	}
`;

export const XNotificationBar = styled(Card)`
	border-radius: 20px;
	background: var(
		--Linear,
		linear-gradient(
			90deg,
			rgba(105, 234, 203, 0.15) 0%,
			rgba(234, 204, 248, 0.15) 48%,
			rgba(102, 84, 241, 0.15) 100%
		)
	);
	.ant-card-body {
		text-align: center;
		padding: 15px !important;
		border-radius: 20px;
		border: none;
		@media only screen and (max-width: 768.99px) {
			padding: 10px !important;
		}
	}
	z-index: 1111;
`;

export const XButtonCloseBar = styled(Button)`
	position: absolute;
	right: 1%;
	transform: translateY(-50%);
	background-color: transparent;
	border: none;
	font-size: 2em;
	color: grey;
	cursor: pointer;
	&:hover {
		color: inherit;
	}
`;

export const XButtonReload = styled(Button)`
	border-radius: 400px;
	background: #212226;
	color: #fff;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: -0.4px;
	border: none;
`;

export const XNotificationCard = styled(Card)`
	border-radius: 20px;
	background: var(
		--Linear,
		linear-gradient(
			90deg,
			rgba(105, 234, 203, 0.15) 0%,
			rgba(234, 204, 248, 0.15) 48%,
			rgba(102, 84, 241, 0.15) 100%
		)
	);
	.ant-card-body {
		padding: 15px !important;
		border-radius: 20px;
		border: none;
		@media only screen and (max-width: 768.99px) {
			padding: 10px !important;
		}
	}
	position: fixed;
	border-radius: 5px;
	box-sizing: border-box;
	color: black;
	font-size: 1em;
	margin: 0 auto;
	margin-bottom: 1em;
	padding: 0.5em;
	width: 100%;
	top: 0;
	z-index: 1;
`;

export const XCheckboxGroup = styled(Checkbox.Group)`
	.ant-checkbox-group-item {
		margin-bottom: 10px;
	}
	.ant-checkbox-wrapper-checked .ant-checkbox-inner {
		background: #007299 !important;
		color: #fff !important;
	}
`;

export const XRecommendedCard = styled(Card)`
	// background: ${({ theme }) => theme.colors.white};
	background: ${({ bgColor }) => bgColor || '#f5f6ff'};
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	margin-bottom: 15px;
	.hoverGradient {
		font-size: 16px;
		color: #007299;
		// padding: 0px 5px;
		// background: var(
		// 	--Linear,
		// 	linear-gradient(90deg, #d4f8eb 0%, #fff3fc 48%, #e4d3fc 100%)
		// );
	}
	&:hover {
		background: #eaebfb;
		// .hoverGradient {
		// 	padding: 0px 5px;
		// 	background: #d4d6fb;
		// 	color: #7a82fd !important;
		// }
		div {
			color: #7a82fd !important;
		}
	}
	.ant-card-body {
		padding: 0;
	}
`;
export const XInputText = styled(Input)`
	border: 1px solid rgba(87, 99, 111, 0.15);
	border-radius: 10px;
	background: ${({ theme }) => theme.colors.white};
	font-weight: 400;
	font-size: 14px;
	line-height: 30px;
	letter-spacing: -0.02em;
	&:hover,
	&:focus {
		// border-color: #007299;
	}
`;
export const SearchButton = styled(Button)`
	// border-radius: 20px !important,
	// color: #007299 !important,
	width: 100%;
	font-family: ${({ theme }) => theme.colors.fontInter} !important;
	padding: 12px 0px;
	height: auto !important;

	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 14px;
	letter-spacing: 0.28px;
`;

export const XCardRelatedCodes = styled(Card)`
	border: 1px solid rgba(87, 99, 111, 0.15);
	border-radius: 10px;
	.ant-card-body {
		padding: 12px;
	}
`;
export const XCollapseMain = styled(Collapse)`
	.ant-collapse-item .ant-collapse-header {
		background: #fff !important;
		border-radius: 8px !important;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		color: #212226;
	}
	.ant-collapse-content {
		width: 100%;
		position: relative;
		padding: 0px 5px 15px 0px;
		max-height: 45vh;
		overflow-y: auto;
		overflow-x: hidden;
		&::-webkit-scrollbar {
			width: 2px;
			height: 2px;
			background: #f4f3f3;
		}
		&::-webkit-scrollbar-thumb {
			background: #9b9b9b;
		}
		&::-webkit-scrollbar {
			width: 3px;
			height: 3px;
			background: #f4f3f3;
		}
		&::-webkit-scrollbar-thumb {
			background: #9b9b9b;
		}
	}
`;
export const XCollapseInner = styled(Collapse)`
	.ant-collapse-item .ant-collapse-header {
		background: #fff !important;
		font-weight: 400;
		font-size: 14px;
		line-height: 28px;
		letter-spacing: -0.02em;
		color: #212226;
	}
	.ant-collapse-item,
	.ant-collapse-item:last-child {
		border: 1px solid rgba(87, 99, 111, 0.15);
		border-radius: 10px !important;
		margin-bottom: 15px;
	}
	.ant-collapse-content-box {
		padding-top: 0px !important;
	}
	.paraHead {
		font-weight: 500;
		font-size: 14px;
		line-height: 22px;
		letter-spacing: -0.02em;
		color: ${({ theme }) => theme.colors.menu2};
	}
	p.paragraph {
		margin: 10px 0px 0px 0px;
		font-weight: 400;
		font-size: 12px;
		line-height: 18px;
		letter-spacing: -0.02em;
		color: ${({ theme }) => theme.colors.menu2};
	}
	.ant-collapse-content {
		width: 100%;
		position: relative;
		padding: 0px;
		max-height: 100%;
		height: auto;
		&::-webkit-scrollbar {
			width: 0px;
			height: 0px;
			background: #fff;
		}
		&::-webkit-scrollbar-thumb {
			background: #fff;
		}
		&::-webkit-scrollbar {
			width: 0px;
			height: 0px;
			background: #fff;
		}
		&::-webkit-scrollbar-thumb {
			background: #fff;
		}
	}
`;
export const XViewMoreBTN = styled(Button)`
	background: #fff;
	border: 1px solid rgba(0, 114, 153, 0.1);
	filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.15));
	border-radius: 5px;
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	letter-spacing: -0.02em;
	color: #007299;
	&:hover {
		background: #007299 !important;
		color: #fff !important;
	}
`;
export const XButtonAddDate = styled(Button)`
	width: 100%;
	background: ${({ theme }) => theme.colors.white} !important;
	color: #007299 !important;
	border-color: #007299 !important;
	border-radius: 10px !important;
	margin: 0px !important;
	@media only screen and (max-width: 767.99px) {
	}
`;

export const XTagDate = styled(Tag)`
	background: #f2f2f2;
	border: 1px solid rgba(18, 18, 73, 0.15);
	color: #fff;
	padding: 10px 15px;
	font-weight: 400;
	font-size: 16px;
	line-height: 22px;
	color: #11263c;
	.anticon-close {
		padding: 3px;
		font-size: 12px;
		background: #000;
		color: #fff;
		border-radius: 15px;
		margin-left: 30px;
		&:hover {
			color: #fff;
		}
	}
	@media only screen and (max-width: 767.99px) {
	}
`;

export const XuploadAddCase = styled(Upload)`
	width: 100%;
	background: transparent;
	text-align: center;
	button {
		// width: 100% !important;
		height: 100% !important;
		border-radius: 10px !important;
		display: block !important;
	}
	.ant-upload {
		width: 100%;
	}
	.ant-upload-list-item-actions {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	@media only screen and (max-width: 767.99px) {
	}
`;
export const XRecommendedMobCard = styled(Card)`
	background: ${({ bgColor }) => bgColor || '#f5f6ff'};
	.ant-card-head {
		min-height: auto !important;
		padding: 5px 10px;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 28px;
		letter-spacing: -0.02em;
		color: #212226;
		border: none;
	}
	.ant-card-body {
		padding: 0px 15px;
	}
	@media only screen and (max-width: 991.99px) {
		.ant-card-body {
			padding: 15px 5px 15px 15px !important;
		}
	}
	.ant-btn-compact-first-item {
		display: none;
	}
	.ant-btn-icon {
		font-size: 30px;
		transform: rotate(90deg);
		svg {
			color: #007299;
			font-size: 22px;
		}
	}
	.drowdownTitle {
		font-weight: 500;
		font-size: 14px;
		line-height: 18px;
		letter-spacing: -0.02em;
		color: #212226;
	}
	.drowdownSubTitle {
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		letter-spacing: -0.02em;
		color: #007299;
	}
`;
export const XMobDropdown = styled(Dropdown)``;
